import thunk from "redux-thunk"
import { createStore, applyMiddleware, combineReducers, compose } from "redux"

import ProductsReducer from "./products/ProductsReducer"
import SidebarReducer from "./sidebar/SidebarReducer"
import CartReducer from "./cart/CartReducer"
import NotificationReducer from "./notifications/NotificationReducer"
import { firebaseReducer, getFirebase } from "react-redux-firebase"
import { firestoreReducer, reduxFirestore, getFirestore } from "redux-firestore"
import firebase from '../firebase/app'
import ApplicationReducer from "./application/ApplicationReducer"
import OrdersReducer from "./orders/OrdersReducer"
import AuthenticationReducer from "./auth/AuthenticationReducer"

const rootReducer = combineReducers({
  application: ApplicationReducer,
  products : ProductsReducer,
  sidebar : SidebarReducer,
  cart : CartReducer,
  orders: OrdersReducer,
  notifications : NotificationReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  auth: AuthenticationReducer
})

// Add reduxFirestore store enhancer to store creator
const createStoreWithFirebaseAndThunk = compose(
    applyMiddleware(thunk.withExtraArgument({getFirebase,getFirestore})),
    reduxFirestore(firebase, {attachAuthIsReady: true, useFirestoreForProfile: true, userProfile: 'users'})
)(createStore)

// Create store with reducers and initial state
const initialState = {}
const store = createStoreWithFirebaseAndThunk(rootReducer, initialState)

export default store;
