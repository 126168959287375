const firebaseConfig = {
    apiKey: "AIzaSyAMoOkX0KvhGGM5UPhKosGs_P8g8T2EUQE",
    authDomain: "berzel-65d5a.firebaseapp.com",
    databaseURL: "https://berzel-65d5a.firebaseio.com",
    projectId: "berzel-65d5a",
    storageBucket: "berzel-65d5a.appspot.com",
    messagingSenderId: "258511522100",
    appId: "1:258511522100:web:94ced2f97d940107bd7d61"
};

export default firebaseConfig