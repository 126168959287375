import CartActionTypes from "./CartActionTypes"
import { notify } from "../notifications/NotificationActions"
import {v4 as uuid} from "uuid"
import NotificationTypes from "../notifications/NotificationTypes"


export const toggleCart = () => ({
    type: CartActionTypes.TOGGLE
})

export const openCart = () => ({
    type: CartActionTypes.OPEN
})

export const closeCart = () => ({
    type: CartActionTypes.CLOSE
})

export const clearCart = () => ({
    type: CartActionTypes.CLEAR
})

export const addItemToCart = (item) => ({
    type: CartActionTypes.ADD_ITEM,
    payload : item
})

export const deleteItemFromCart = (item) => ({
    type: CartActionTypes.DELETE_ITEM,
    payload : item
})

export const updateItemInCart = (itemId, qty) => ({
    type: CartActionTypes.UPDATE_ITEM,
    payload : {
        itemId, qty
    }
})

export const addToCart = (item) => {
    return dispatch => {
        dispatch(addItemToCart(item))

        let notification = {
            id : uuid(),
            type : NotificationTypes.SUCCESS,
            message : 'Added to cart.'
        }
        
        dispatch(notify(notification))
    }
}

export const updateItem = (itemId, qty) => {
    return dispatch => {
        dispatch(updateItemInCart(itemId, qty))
        
        if (qty > 0){
            let notification = {
                id : uuid(),
                type : NotificationTypes.SUCCESS,
                message : 'Cart updated.'
            }
            
            dispatch(notify(notification))
        }
    }
}

export const deleteFromCart = (item) => {
    return dispatch => {
        dispatch(deleteItemFromCart(item))
        
        let notification = {
            id : uuid(),
            type : NotificationTypes.SUCCESS,
            message : 'Removed from cart.'
        }
        
        dispatch(notify(notification))
    }
}