import React from 'react'
import AppLayout from '../layouts/AppLayout'
import CartItems from '../partials/CartItems'
import { connect } from 'react-redux'
import CartSummary from '../partials/CartSummary'
import { Redirect } from 'react-router-dom'
import { placeOrder } from '../../redux/orders/OrdersActions'

class Checkout extends React.Component {

    constructor(props){
        super(props)
        let order = localStorage.getItem('order')
        this.state = order ? JSON.parse(order) : {}
    }

    handleChange = event => {
        let name = event.target.name
        let value = event.target.value

        this.setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    placeOrder = event => {
        event.preventDefault()
        localStorage.setItem('order', JSON.stringify(this.state))
        this.props.placeOrder(this.state)
    }

    render () {
        let {items} = this.props

        if (items.length <= 0) {
            return (
                <Redirect to='/' />
            )
        }

        if (this.props.orderPlaced && this.props.placedOrderRef && !this.props.orderPlacing) {
            return (
                <Redirect from='/checkout' to={`/orders/${this.props.placedOrderRef.id}/payment`} />
            )
        }
    
        document.title = 'Techboss | Checkout'

        return (
            <AppLayout>
                <section>
                <h2 className="main-heading" style={{margin: '0.5em 0 2em'}}><span className="main-heading__left">Ch</span><span className="main-heading__right" style={{marginTop: 0}}>eckout</span></h2>

                    <div className="checkout">
                        <form className="panel shadowed" style={{padding: '1em', backgroundImage: 'linear-gradient(to bottom, #fff 35em, var(--gray-100))'}} method="post" onSubmit={this.placeOrder}>
                            <div className="section-title">
                                <h3 className="section-title__heading">Contact</h3>
                                <div className="section-title__desc">Who are we sending to?</div>
                                <div className="hr"></div>
                            </div>
                            <div>
                                <input  className="search-bar__input form-input"
                                    type="text" placeholder="Fullname" name='fullname' required value={this.state.fullname ?? ''} onChange={this.handleChange}/>
                            </div>
                            <input type="text" placeholder="Phone number" className="search-bar__input form-input"
                                 name='phone' required value={this.state.phone ?? ''} onChange={this.handleChange} />
    
                            <div className="section-title" style={{marginTop: '3em'}}>
                                <h3 className="section-title__heading">Shipping</h3>
                                <div className="section-title__desc">Where should we send your items?</div>
                                <div className="hr"></div>
                            </div>
                            <input type="text" placeholder="House number & Street name" className="search-bar__input form-input"
                                name='street' required value={this.state.street ?? ''} onChange={this.handleChange} />
                            <input type="text" placeholder="City area or District" className="search-bar__input form-input"
                                name='surbarb' required value={this.state.surbarb ?? ''} onChange={this.handleChange} />

                            <select className="search-bar__input form-input" style={{fontSize: '1.2rem', padding: '0.9em 0.6em', fontWeight: 300, color: 'var(--gray-900)', fontFamily: 'inherit'}} name='city' required onChange={this.handleChange} value={this.state.city ?? ''}>
                                <option value=''>Town or City</option>
                                <option value="Bulawayo">Bulawayo, ZW</option>
                            </select>

                            <div className="section-title" style={{marginTop: '2em'}}>
                                <h3 className="section-title__heading">Order review</h3>
                                <div className="section-title__desc">Verify what's in your basket...</div>
                                <div className="hr"></div>
                            </div>
    
                            <CartItems items={items} />
                            
                            <div className="hr"></div>
    
                            <CartSummary />
                            
                            <button disabled={this.props.orderPlacing} type='submit' className="btn btn--secondary" style={ this.props.orderPlacing ?{width: '100%', cursor: 'progress', backgroundColor: 'var(--secondary-light)'} : {width: '100%'}}>{this.props.orderPlacing ? 'Placing order...':'Place order'}</button>
    
                        </form>
                        <div>
                            <div className="section-title">
                                <h3 className="section-title__heading">Order review</h3>
                                <div className="section-title__desc">Verify what's in your basket...</div>
                                <div className="hr"></div>
                            </div>

                        </div>
                    </div>
                    
                </section>
            </AppLayout>
        )
    }
}


const mapStateToProps = state => {
    return {
        items : state.cart.items,
        orderPlacing : state.orders.placing,
        orderPlaced : state.orders.placed,
        placedOrderRef : state.orders.orderRef
    }
}

const mapDispatchToProps = dispatch => {
    return {
        placeOrder : orderDetails => dispatch(placeOrder(orderDetails))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)