import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'

import twitterSvg from '../../img/twitter.svg'
import facebookSvg from '../../img/facebook.svg'
import googleSvg from '../../img/google.svg'
import { signIn } from '../../redux/auth/AuthenticationActions'
import { connect } from 'react-redux'

class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: ''
        }
    }

    handleChange = e => {
        let name = e.target.name
        let value = e.target.value

        this.setState(prevState => ({
            ...prevState,
            [name] : value
        }))
    }

    handleSubmit = e => {
        e.preventDefault()
        this.props.signIn(this.state)
    }

    render () {
        const {loggingIn, error, auth} = this.props

        if (auth && auth.uid) {
            return (
                <Redirect to='/' />
            )
        }

        const formInputStyle = {
            border: 'none', 
            outline: 'none', 
            display: 'block', 
            width: '100%', 
            fontSize: '1.3rem', 
            padding: '0.8em', 
            borderRadius: '0.5em'
        }

        return (
            <div style={{padding: '0 1em 10px', maxWidth: '30em', margin: 'auto'}}>
                <h1 className="main-heading" style={{fontWeight: 600}}><span className="main-heading__left">L</span><span className="main-heading__right">og in</span></h1>
    
                <div style={{display: 'flex', justifyContent: 'space-between', padding: '0 0.5em', marginTop: '3em'}}>
                    <div className='shadowed' style={{backgroundColor: 'rgb(29, 161, 242)', display: 'flex', alignItems: 'center', padding: '0.8em 1em', borderRadius: '0.50em', marginTop: '0'}}>
                        <img style={{width: '1.5em', height: '1.5em'}} src={twitterSvg} alt="Log in with twitter"/>
                        <span style={{marginTop: '0', marginLeft: '0.5em', fontWeight: '600', letterSpacing: '0.0.3em', color: 'var(--light-text)'}}>twitter</span>
                    </div>
                    <div className='shadowed' style={{backgroundColor: '#4267b2', display: 'flex', alignItems: 'center', padding: '0.8em 1em', borderRadius: '0.50em', marginTop: '0'}}>
                        <img style={{width: '1.5em', height: '1.5em'}} src={facebookSvg} alt="Log in with facebook"/>
                        <span style={{marginTop: '0', marginLeft: '0.5em', fontWeight: '600', letterSpacing: '0.0.3em', color: 'var(--light-text)'}}>facebook</span>
                    </div>
                    <div className='shadowed' style={{backgroundColor: '#DD4B39', display: 'flex', alignItems: 'center', padding: '0.8em 1em', borderRadius: '0.50em', marginTop: '0'}}>
                        <img style={{width: '1.5em', height: '1.5em'}} src={googleSvg} alt="Log in with google"/>
                        <span style={{marginTop: '0', marginLeft: '0.5em', fontWeight: '600', letterSpacing: '0.0.3em', color: 'var(--light-text)'}}>google</span>
                    </div>
                </div>
                <div className="hr" style={{position: 'relative', top: '4.02em', zIndex: '-1'}}></div>
                <div style={{margin: '2.5em 0', textAlign:'center', fontWeight: 300, fontSize: '1.2rem'}}>
                   <span style={{backgroundColor: 'var(--gray-200)', padding: '0 1em'}}>or log in with email</span>
                </div>
                <div>
                    {error ? <p style={{padding: '1em 0', color: '#DD4B39'}}>{error.message}</p> : null}
                </div>
                <form method="POST" onSubmit={this.handleSubmit}>
                    <input type='email' name='email' value={this.state.email ?? ''} required onChange={this.handleChange} placeholder='Your email' className="shadowed panel" style={formInputStyle} />
                    <input type='password' name='password' value={this.state.password ?? ''} required onChange={this.handleChange} placeholder='Password' className="shadowed panel" style={formInputStyle} />
                    <Link to="/forgot-password" style={{display: 'block', textAlign: 'right', padding: '0 0.5em'}}>Forgot your password?</Link>
                    <button type='submit' disabled={loggingIn ? true : false} className="btn btn--secondary shadowed" style={{display: 'block', marginTop: '2em', borderRadius: '0.5em', width: '100%', fontSize: '1.1rem'}}>{loggingIn ? 'Logging in...' : 'Log in'}</button>
                </form>
                <div style={{textAlign: 'center', fontSize: '1.2rem', margin: '5em 0'}}>Don't have an account? <Link to="/register">Sign up now!</Link></div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    error : state.auth.error,
    loggingIn : state.auth.loggingIn
})

const mapDispatchToProps = dispatch => {
    return {
        signIn : creds => dispatch(signIn(creds))
    }
} 

export default connect(mapStateToProps, mapDispatchToProps)(Login)
