import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'

import twitterSvg from '../../img/twitter.svg'
import facebookSvg from '../../img/facebook.svg'
import googleSvg from '../../img/google.svg'
import { connect } from 'react-redux'
import { signUp } from '../../redux/auth/AuthenticationActions'

class Register extends Component {
    constructor (props) {
        super(props)

        this.state = {
            displayName : '',
            email: '',
            password: ''
        }
    }

    handleChange = event => {
        let name = event.target.name
        let value = event.target.value

        this.setState(prevState => ({
            ...prevState,
            [name] : value
        }))
    }

    handleSubmit = event => {
        event.preventDefault()
        this.props.signUp(this.state)
    }
    
    render () {
        const {error, signingUp, auth} = this.props
        
        if (auth && auth.uid) {
            return (
                <Redirect to='/' />
            )
        }

        const formInputStyle = {
            border: 'none', 
            outline: 'none', 
            display: 'block', 
            width: '100%', 
            fontSize: '1.3rem', 
            padding: '0.8em', 
            borderRadius: '0.5em'
        }

        return (
            <div style={{padding: '0 1em', maxWidth: '30em', margin: 'auto'}}>
            <h1 className="main-heading" style={{fontWeight: 600}}><span className="main-heading__left">S</span><span className="main-heading__right">ign up</span></h1>
    
            <div style={{display: 'flex', justifyContent: 'space-between', padding: '0 0.5em', marginTop: '3em'}}>
                <div className='shadowed' style={{backgroundColor: 'rgb(29, 161, 242)', display: 'flex', alignItems: 'center', padding: '0.8em 1em', borderRadius: '0.50em', marginTop: '0'}}>
                    <img style={{width: '1.5em', height: '1.5em'}} src={twitterSvg} alt="Log in with twiiter"/>
                    <span style={{marginTop: '0', marginLeft: '0.5em', fontWeight: '600', letterSpacing: '0.0.3em', color: 'var(--light-text)'}}>twitter</span>
                </div>
                <div className='shadowed' style={{backgroundColor: '#4267b2', display: 'flex', alignItems: 'center', padding: '0.8em 1em', borderRadius: '0.50em', marginTop: '0'}}>
                    <img style={{width: '1.5em', height: '1.5em'}} src={facebookSvg} alt="Log in with twiiter"/>
                    <span style={{marginTop: '0', marginLeft: '0.5em', fontWeight: '600', letterSpacing: '0.0.3em', color: 'var(--light-text)'}}>facebook</span>
                </div>
                <div className='shadowed' style={{backgroundColor: '#DD4B39', display: 'flex', alignItems: 'center', padding: '0.8em 1em', borderRadius: '0.50em', marginTop: '0'}}>
                    <img style={{width: '1.5em', height: '1.5em'}} src={googleSvg} alt="Log in with twiiter"/>
                    <span style={{marginTop: '0', marginLeft: '0.5em', fontWeight: '600', letterSpacing: '0.0.3em', color: 'var(--light-text)'}}>google</span>
                </div>
            </div>
            <div className="hr" style={{position: 'relative', top: '4.02em', zIndex: '-1'}}></div>
            <div style={{margin: '2.5em 0', textAlign:'center', fontWeight: 300, fontSize: '1.2rem'}}>
               <span style={{backgroundColor: 'var(--gray-200)', padding: '0 1em'}}>or sign up with email</span>
            </div>
            <div>
                {error ? <p style={{padding: '1em 0', color: '#DD4B39'}}>{error.message}</p> : null}
            </div>
            <form method="POST" onSubmit={this.handleSubmit}>
                <input name='displayName' value={this.state.displayName ?? ''} onChange={this.handleChange} required type='text' placeholder='Full name' className="shadowed panel" style={formInputStyle} />
                <input name='email' value={this.state.email ?? ''} onChange={this.handleChange} required type='email' placeholder='Your email' className="shadowed panel" style={formInputStyle} />
                <input name='password' value={this.state.password ?? ''} onChange={this.handleChange} required type='password' placeholder='Password' className="shadowed panel" style={formInputStyle} />
                <button type='submit' className="btn btn--secondary shadowed" style={{display: 'block', marginTop: '2.5em', borderRadius: '0.5em', width: '100%', fontSize: '1.1rem'}}>{signingUp ? 'Signing up...' : 'Sign up'}</button>
            <div style={{textAlign: 'center', fontSize: '1.2rem', margin: '1em'}}>By signing up on TechBoss, you agree to our <Link to="/login">terms and conditions.</Link></div>
            </form>
            <div style={{textAlign: 'center', fontSize: '1.2rem', margin: '3em 0'}}>Already have an account? <Link to="/login">Log in!</Link></div>
        </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    error : state.auth.error,
    signingUp: state.auth.signingUp
})

const mapDispatchToProps = dispatch => ({
    signUp : details => dispatch(signUp(details))
})

export default connect(mapStateToProps, mapDispatchToProps)(Register)
