import AuthenticationActionTypes from "./AuthenticationActionTypes"

const initialState = {
    signingUp: false,
    loggingIn : false,
    loggingOut: true,
    requestingResetEmail: false,
    displayName : null,
    uid: null,
    error : null
}

const AuthenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthenticationActionTypes.SET_USER :
        return action.payload.user ? {
            ...state,
            displayName : action.payload.user.displayName,
            uid: action.payload.user.uid
        } : {
            ...state,
            displayName: null,
            uid: null
        }

    case AuthenticationActionTypes.SIGNING_UP :
        return {
            ...state,
            signingUp : true,
            error: null
        }

    case AuthenticationActionTypes.SIGNED_UP :
        return {
            ...state,
            signingUp : false,
            error: null
        }

    case AuthenticationActionTypes.SIGNUP_ERROR :
        return {
            ...state,
            signingUp : false,
            error: action.payload.err
        }

    case AuthenticationActionTypes.EMAIL_VERIFICATION_SENT :
        return {
            ...state,
            signingUp : false,
            error: null
        }    

    case AuthenticationActionTypes.LOGGING_IN :
      return {
        ...state,
        loggingIn: true,
        error: null
      }
    
    case AuthenticationActionTypes.LOGIN_SUCCESS : 
      return {
          ...state,
          loggingIn: false,
          error : null
      }
    
    case AuthenticationActionTypes.LOGIN_ERROR :
        return {
            ...state,
            loggingIn: false,
            error: action.payload.err
        }

    case AuthenticationActionTypes.LOGGING_OUT :
        return {
            ...state,
            loggingOut: true
        }

    case AuthenticationActionTypes.LOGGED_OUT :
        return {
            ...state,
            loggingOut: false
        }

    case AuthenticationActionTypes.LOGOUT_ERROR :
        return {
            ...state,
            loggingOut: false,
            error: action.payload.err
        }

    case AuthenticationActionTypes.REQUESTING_RESET_EMAIL :
        return {
            ...state,
            requestingResetEmail: true,
            error: null
        }

    case AuthenticationActionTypes.REQUEST_RESET_EMAIL_SUCCESS :
        return {
            ...state,
            requestingResetEmail: false,
            error: null
        }

    case AuthenticationActionTypes.REQUEST_RESET_EMAIL_ERROR :
        return {
            ...state,
            requestingResetEmail: false,
            error: action.payload.err
        }

    default :
      return state
  }
}

export default AuthenticationReducer