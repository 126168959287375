import React from 'react'
import {connect} from 'react-redux'
import {v4 as uuid} from 'uuid'

import AppLayout from '../layouts/AppLayout'
import ProductRating from '../partials/ProductRating'
import ProductSection from '../partials/ProductSection'
import AddToCartForm from '../partials/AddToCartForm'
import { formatQuotient, formatRemainder } from '../../helpers'
import SlideShow from '../partials/Slideshow'
import { compose } from 'redux'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'

const ProductDetails = (props) => {
    let {product, physicals} = props
    let loading = !isLoaded(product)

    if (isEmpty(product)) {
        return (
            <div>
                Not found
            </div>
        )
    }

    if (isLoaded(product)){
        document.title = `Techboss | ${product.name}`
    }

    return (
        <AppLayout>
            {loading ? <div>Loading...</div> : 
                <React.Fragment>
                    <div className="product-detail">
                        <SlideShow product={product} />
                        <div className="product-detail__overview" style={{zIndex: 1000}}>
                            <div className="shadowed product-detail__content">

                                <div className="product-detail__carousel">
                                    <ul className="product-detail__carousel__links">
                                        <li className="product-detail__carousel__link"></li>
                                        <li className="product-detail__carousel__link"></li>
                                        <li className="product-detail__carousel__link"></li>
                                        <li className="product-detail__carousel__link"></li>
                                        <li className="product-detail__carousel__link"></li>
                                    </ul>
                                </div>
                                <div>
                                    <h2 className="product-detail__price">${formatQuotient(product.price)}<span
                                            className="product-detail__price__cents">.{formatRemainder(product.price)}</span></h2>
                                </div>
                                <div className="hr"></div>
                                <div>
                                    <h2>{product.name}</h2>
                                    <div>
                                        <span>By <a href="/">TechBoss</a>.</span><span className="product-detail__stock">{product.quantity} <span style={{color: 'green'}}>in stock</span></span>
                                        <ProductRating/>
                                    </div>
                                </div>
                                <div className="hr"></div>
                                <AddToCartForm product={{...product, id: `${props.match.params.id}`}} />
                            </div>
                            <div className="product-detail__desc">

                                <div className="shadowed panel" style={{borderRadius: '0.3em'}}>
                                    <a style={{display: 'inline-block', margin: 0, padding: '0.5em 1em', fontWeight: 500, borderBottom: '2px solid var(--secondary)'}} href="/">Description</a>
                                    <a style={{display: 'inline-block', margin: 0, padding: '0.5em 1em', fontWeight: 500}} href="/">Reviews</a>
                                    <a style={{display: 'inline-block', margin: 0, padding: '0.5em 1em', fontWeight: 500}} href="/">FAQ's</a>
                                </div>
                                <div className="shadowed panel product-detail__desc__content">
                                    <p className="product-desc">
                                        {product.description}
                                    </p>
                                    <div className="hr"></div>
                                    <h4 className="product-desc__heading">Facts & Features </h4>
                                    <div className="hr"></div>
                                    <ul className="product-spec__list">
                                        {product.specs && product.specs.map(spec => 
                                            <li key={uuid()} className="product-spec">{spec}</li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ProductSection 
                        title={`Top selling groceries`} 
                        description='Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est, quibusdam' 
                        products={physicals}
                    />

                    <ProductSection 
                        title={`Top selling groceries`} 
                        description='Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est, quibusdam' 
                        products={physicals}
                    />

                </React.Fragment>
            }
        </AppLayout>
    )
}

export default compose(
    firestoreConnect(ownProps => [
        {
            collection: 'products',
            doc : ownProps.match.params.id,
            storeAs: `products/${ownProps.match.params.id}`
        },
        {
            collection: 'products',
            limit : 4,
            where: [
                ['department', '==', 'groceries']
            ],
            storeAs: 'top-selling'
        },
    ]),

    connect((state, ownProps) => ({
        physicals: state.firestore.data['top-selling'],
        product: state.firestore.data[`products/${ownProps.match.params.id}`]
    }))
)(ProductDetails)