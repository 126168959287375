import React from 'react'
import Notification from './Notification'
import { connect } from 'react-redux'

const Notifications = ({notifications}) => {
    return (
        <ul className="notifications">
            {notifications && notifications.map(n => <Notification key={n.id} notification={n} />)}
        </ul>
    )
}

const mapStateToProps = state => {
    return {
        notifications: state.notifications.data
    }
}

export default connect(mapStateToProps)(Notifications)