import ApplicationActionTypes from "./ApplicationActionTypes"
import { setAuthUser } from "../auth/AuthenticationActions"

export const onApplicationLoading = () => ({
  type: ApplicationActionTypes.APPLICATION_LOADING
})

export const onApplicationLoaded = () => ({
  type: ApplicationActionTypes.APPLICATION_LOADED
})

export const onApplicationLoadError = error => ({
  type: ApplicationActionTypes.APPLICATION_LOAD_ERROR,
  payload: {
    error
  }
})

export const loadApplication = () => {
  return (dispatch, getState, {getFirebase}) => {
    dispatch(onApplicationLoading())

    getFirebase().auth().onAuthStateChanged(user => {
        dispatch(setAuthUser(user))
    })

    setTimeout(() => {
      dispatch(onApplicationLoaded())
    }, 1);
  }
}