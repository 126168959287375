import React from 'react'
import { withRouter } from 'react-router-dom'

class HomepageSearchForm extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            query: ''
        }
    }

    handleChange = event => {
        let value = event.target.value
        this.setState(prevState => ({
            ...prevState,
            query: value
        }))
    }

    handleSubmit = event => {
        event.preventDefault()
        this.props.history.push(`/search/?query=${encodeURI(this.state.query)}`)
    }


    render () {
        return (
            <form className="shadowed homepage-search__bar" style={{borderRadius: '0.5em'}} onSubmit={this.handleSubmit} >
                <input type='text' placeholder='Search on TechBoss' className="shadowed panel" style={{border: 'none', outline: 'none', display: 'block', width: '100%', fontSize: '1.2rem', padding: '0.8em', borderRadius: '0.5em'}} value={this.state.query} onChange={this.handleChange} />

                <button className="btn btn--secondary shadowed" style={{display: 'block', marginTop: '1em', borderRadius: '0.5em', width: '100%', fontSize: '1.1rem'}}>Search products</button>
            </form>
        )
    }
}

export default withRouter(HomepageSearchForm)