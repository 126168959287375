import React from 'react'
import {Link} from 'react-router-dom'

import ProductRating from './ProductRating'
import { formatQuotient, formatRemainder } from '../../helpers'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'

const ProductSummary = ({product, productId}) => {
    if (product) {
        return (
            <div className="product-summary">
                <Link to={`/products/${productId}`} className="product-summary__img">
                    <img src={product.images ? product.images[0] : 'https://via.placeholder.com/1280x900.png'} alt={product.name}/>
                </Link>
                <div className="product-summary__details">
                    <Link to={`/search?query=${product.category}`} className="badge">{product.category}</Link>
                    <p className="product-summary__title"><Link to={`/products/${productId}`}>{product.name}</Link></p>
                    <p className="product-summary__price">${formatQuotient(product.price)}<span style={{fontSize: '0.9rem', fontWeight: 400}}>.{formatRemainder(product.price)}</span> </p>
                    <ProductRating />
                </div>
            </div>
        )
    }

    return (
        <div>
            Product Loading...
        </div>
    )
}

export default compose(
    firestoreConnect(ownProps => [
        {
            collection: 'products',
            doc : ownProps.productId,
            storeAs: `products/${ownProps.productId}`
        }
    ]),

    connect((state, ownProps) => ({
        product: state.firestore.data[`products/${ownProps.productId}`]
    }))
)(ProductSummary)