import React from 'react'
import {connect} from 'react-redux'

import AppLayout from '../layouts/AppLayout'
import ProductSection from '../partials/ProductSection'
import SearchForm from '../partials/SearchForm'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

const PhysicalsHome = ({beans, rice, chargers}) => {
    return (
        <AppLayout>
            <div className="mobile-search">
                <SearchForm />
            </div>

            <ProductSection 
                title='Recently Added' 
                description='The latest in store today' 
                products={chargers}
            />

            <ProductSection 
                title='Top Selling' 
                description='Do not be left out' 
                products={rice}
            />

            <ProductSection 
                title='Recommended for you' 
                description='Something amazing you may fall in love with' 
                products={beans}
            />

            <ProductSection 
                title='Top rated' 
                description='Deeply satifying items you might also like' 
                products={rice}
            />  
        </AppLayout>
    )
}

export default compose(
    firestoreConnect(props => [
        {
            collection: 'products',
            limit : 4,
            where: [
                ['category', '==', 'laptop chargers']
            ],
            storeAs: 'chargers'
        },
        {
            collection: 'products',
            limit : 4,
            where: [
                ['category', '==', 'rice']
            ],
            storeAs: 'rice'
        },
        {
            collection: 'products',
            limit : 4,
            where: [
                ['category', '==', 'baked beans']
            ],
            storeAs: 'beans'
        }
    ]),

    connect((state) => ({
        beans: state.firestore.data['beans'],
        rice: state.firestore.data['rice'],
        chargers: state.firestore.data['chargers']
    }))
)(PhysicalsHome)