import { connectInfiniteHits } from "react-instantsearch-dom"
import React from 'react'
import ProductList from "./ProductList"

const CustomInfiniteHits = ({hits,hasMore,refineNext}) => {

    if (hits.length === 0) {
        return (
            <div>
                <h1>Nothing was found</h1>
            </div>
        )
    }

    if (hits.length > 0) {
        let title = 'Search results'
        let description = 'Your search results will appear here'
        
        hits = hits.map(hit => ({
            ...hit,
            id: hit.objectID
        }))

        return (
            <React.Fragment>
                <section style={{marginTop: '4em'}}>
                    <div className="section-title" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div>
                            <h3 className="section-title__heading">{title}</h3>
                            <div className="section-title__desc">{description}</div>
                        </div>
                        <div style={{margin: 0, fontWeight: 600, marginRight: '0.7em', marginLeft: '0.5em'}}>
                            <a href=" " style={{display: 'block'}}>&#8592;</a>
                            <a href=" " style={{display: 'block', marginTop: '0.2em'}}>&#8594;</a>
                        </div>
                    </div>
                    <div className="hr"></div>
                    <div className="product-row">
                        <ProductList products={hits} />
                    </div>
                </section>
            {hasMore ? 
                <button 
                    onClick={refineNext} 
                    className='btn btn--secondary' 
                    style={{display: 'block', width: '100%', marginTop: '1em'}}>Load More</button> : null}
            </React.Fragment>
        )
    }

    return null
}

export default connectInfiniteHits(CustomInfiniteHits)