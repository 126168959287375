import React from 'react'
import AppLayout from '../layouts/AppLayout'
import TopNav from '../partials/TopNav'
import { connect } from 'react-redux'
import ProductSection from '../partials/ProductSection'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

const GroceriesHome = ({fresh, beans, cereals, soaps, rice}) => {
    document.title = 'TechBoss | Grocery shopping made simple'
    return (
        <AppLayout>
            <TopNav />

            <ProductSection 
                title='Fresh Produce' 
                description='Fresh from the farm to your door' 
                products={fresh}
            />

            <ProductSection 
                title='Cereals & Flakes' 
                description='A perfect way to start your day' 
                products={cereals}
            />

            <ProductSection 
                title='Baked Beans' 
                description='The latest in store today' 
                products={beans}
            />

            <ProductSection 
                title='Rice' 
                description='Always be clean yeh!' 
                products={rice}
            />

            <ProductSection 
                title='Soaps' 
                description='Always be clean yeh!' 
                products={soaps}
            />
        </AppLayout>
    )
}

export default compose(
    firestoreConnect(props => [
        {
            collection: 'products',
            limit : 4,
            where: [
                ['category', '==', 'fresh']
            ],
            storeAs: 'fresh'
        },
        {
            collection: 'products',
            limit : 4,
            where: [
                ['category', '==', 'baked beans']
            ],
            storeAs: 'beans'
        },
        {
            collection: 'products',
            limit : 4,
            where: [
                ['category', '==', 'cereals']
            ],
            storeAs: 'cereals'
        },
        {
            collection: 'products',
            limit : 4,
            where: [
                ['category', '==', 'soap']
            ],
            storeAs: 'soaps'
        },
        {
            collection: 'products',
            limit : 4,
            where: [
                ['category', '==', 'rice']
            ],
            storeAs: 'rice'
        }
    ]),

    connect((state) => ({
        fresh: state.firestore.data['fresh'],
        beans: state.firestore.data['beans'],
        soaps: state.firestore.data['soaps'],
        rice: state.firestore.data['rice'],
        cereals: state.firestore.data['cereals']
    }))
)(GroceriesHome)