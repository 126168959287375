import React from 'react'
import { Link } from 'react-router-dom'

const ResetPassword = () => {
    return (
        <div style={{padding: '0 1em', maxWidth: '30em', margin: 'auto'}}>
        <h1 className="main-heading" style={{fontWeight: 600}}><span className="main-heading__left">R</span><span className="main-heading__right">eset password</span></h1>

        <form method="POST">
            <input type='password' placeholder='New password' className="shadowed panel" style={{border: 'none', outline: 'none', display: 'block', width: '100%', fontSize: '1.3rem', padding: '0.8em', borderRadius: '0.5em'}} />
            <input type='password' placeholder='Confirm password' className="shadowed panel" style={{border: 'none', outline: 'none', display: 'block', width: '100%', fontSize: '1.3rem', padding: '0.8em', borderRadius: '0.5em'}} />
            <button className="btn btn--secondary shadowed" style={{display: 'block', marginTop: '1.3em', borderRadius: '0.5em', width: '100%', fontSize: '1.1rem'}}>Reset password</button>
        </form>
        <div style={{textAlign: 'center', fontSize: '1.2rem', margin: '5em 0'}}>Already have an account? <Link to="/login">Sign in!</Link></div>
    </div>
    )
}

export default ResetPassword