import React from 'react'
import CartItem from './CartItem'

const CartItems = ({items}) => {
    return (
        <React.Fragment>
            {items.length > 0 ? items.map(item => <CartItem key={item.id} itemId={item.id} />) : <h3 style={{fontWeight: 400}}>Cart is empty</h3>}
        </React.Fragment>
    )
}

export default CartItems