const CartActionTypes = {
    TOGGLE : 'TOGGLE_CART',
    OPEN : 'OPEN_CART',
    CLOSE : 'CLOSE_CART',
    CLEAR : 'CLEAR_CART',
    ADD_ITEM : 'ADD_ITEM',
    DELETE_ITEM: 'DELETE_ITEM',
    UPDATE_ITEM: 'UPDATE_ITEM'
  }
  
  export default CartActionTypes