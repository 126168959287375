import SidebarActionTypes from "./SidebarActionTypes";

const initialState = {
    isOpen : false
}

const SidebarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SidebarActionTypes.TOGGLE:
            return {
                ...state,
                isOpen : !state.isOpen
            }

        case SidebarActionTypes.OPEN:
            return {
                ...state,
                isOpen : true
            }

        case SidebarActionTypes.CLOSE:
            return {
                ...state,
                isOpen : false
            }
    
        default:
            return state
    }
}

export default SidebarReducer