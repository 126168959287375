import React from 'react'

const TopNav = () => {
    return (
        <React.Fragment>
            <div className='top-nav'>
                <a href=' ' className="panel shadowed top-nav__item">
                    Fruits & Veges
                </a>
                <a href=' ' className="panel shadowed top-nav__item">
                    Butchery
                </a>
                <a href=' ' className="panel shadowed top-nav__item">
                    Groceries
                </a>
                <a href=' ' className="panel shadowed top-nav__item">
                    Liquor
                </a>
                <a href=' ' className="panel shadowed top-nav__item">
                    Personal Care
                </a>
                <a href=' ' className="panel shadowed top-nav__item">
                Miscellaneous
                </a>
            </div>
            <div style={{marginBottom: '1.5em', marginTop: 0}} className="hr"></div>
        </React.Fragment>
    )
}

export default TopNav