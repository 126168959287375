import SidebarActionTypes from "./SidebarActionTypes";

export const toggleSidebar = () => ({
    type: SidebarActionTypes.TOGGLE
})

export const openSidebar = () => ({
    type: SidebarActionTypes.OPEN
})

export const closeSidebar = () => ({
    type: SidebarActionTypes.CLOSE
})