import React from 'react'
import AppLayout from '../layouts/AppLayout'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { compose } from 'redux'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import OrderRow from '../partials/OrderRow'

const Products = ({isAdmin, orders}) => {
    if (!isAdmin) {
        return (
            <Redirect to='/' />
        )
    }

    if (isLoaded(orders) && !isEmpty(orders)) {
        orders = Object.keys(orders).map(key => ({
            id: key,
            ...orders[key]
        }))
    }

    return (
        <AppLayout>
            <div className='dashboard'>
                <div className='dashboard__left'>
                    <h2 className="main-heading" style={{margin: 0}}><span className="main-heading__left">Ad</span><span className="main-heading__right">min Dashboard</span></h2>

                    <div style={{display: 'flex', flexWrap: 'wrap', margin: '2em -0.5em 0'}}>
                        <div className='shadowed panel dashboard__overview' style={{padding: '2em', margin: '0.5em', flexGrow: 1}}><p style={{textTransform: 'uppercase', color: 'var(--gray-700)'}}>Products Sold</p><h2 style={{marginLeft: '-0.1em'}}>500</h2></div>
                        <div className='shadowed panel dashboard__overview' style={{padding: '2em', margin: '0.5em', flexGrow: 1}}><p style={{textTransform: 'uppercase', color: 'var(--gray-700)'}}>Products Sold</p><h2 style={{marginLeft: '-0.1em'}}>500</h2></div>
                        <div className='shadowed panel dashboard__overview' style={{padding: '2em', margin: '0.5em', flexGrow: 1}}><p style={{textTransform: 'uppercase', color: 'var(--gray-700)'}}>Products Sold</p><h2 style={{marginLeft: '-0.1em'}}>500</h2></div>
                        <div className='shadowed panel dashboard__overview' style={{padding: '2em', margin: '0.5em', flexGrow: 1}}><p style={{textTransform: 'uppercase', color: 'var(--gray-700)'}}>Products Sold</p><h2 style={{marginLeft: '-0.1em'}}>500</h2></div>
                    </div>


                    <div style={{marginTop: '2em'}}>
                        <h2 className="main-heading" style={{margin: 0}}><span className="main-heading__left">Re</span><span className="main-heading__right">cent Orders</span></h2>

                        <div className='shadowed panel' style={{padding: '1em',  marginTop: '2em', overflowX: 'auto'}}>
                            <table style={{width: '100%', textAlign: 'left', marginTop: 0, borderCollapse: 'collapse'}}>
                                <thead style={{textTransform: 'uppercase', fontWeight: 600, fontSize: '0.8rem'}}>
                                    <tr>
                                        <th style={{borderBottom: '1px solid var(--gray-300)', padding: '0.5em 0 1.2em'}}>Customer</th>
                                        <th style={{borderBottom: '1px solid var(--gray-300)', padding: '0.5em 0 1.2em'}}>Products</th>
                                        <th style={{borderBottom: '1px solid var(--gray-300)', padding: '0.5em 0 1.2em'}}>Total</th>
                                        <th style={{borderBottom: '1px solid var(--gray-300)', padding: '0.5em 0 1.2em'}}>Status</th>
                                        <th style={{borderBottom: '1px solid var(--gray-300)', padding: '0.5em 0 1.2em'}}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoaded(orders) && !isEmpty(orders) ? 
                                        orders.map(order => <OrderRow key={order.id} order={order} />) : <tr><td colSpan='5'>Nothing here</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='dashboard__right'>
                    <h2 className="main-heading" style={{margin: 0}}><span className="main-heading__left">Qu</span><span className="main-heading__right">ick Links</span></h2>
                    <div style={{display: 'flex', flexWrap: 'wrap', margin: '2em -0.5em 0'}}>
                        <a href=' ' className='shadowed panel' style={{display: 'block', backgroundColor: '#fff', marginTop: '0.5em', width: '100%', padding: '0.5em 1em', fontWeight: '400'}}>Create New Product</a>
                        <a href=' ' className='shadowed panel' style={{display: 'block', backgroundColor: '#fff', marginTop: '0.5em', width: '100%', padding: '0.5em 1em', fontWeight: '400'}}>Algolia Search Overview</a>
                        <a href=' ' className='shadowed panel' style={{display: 'block', backgroundColor: '#fff', marginTop: '0.5em', width: '100%', padding: '0.5em 1em', fontWeight: '400'}}>Firebase Usage & Billing</a>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

const mapStateToProps = state => ({
    orders: state.firestore.data.orders,
    isAdmin: state.firebase.profile.isAdmin
})

export default compose(
    firestoreConnect(props => [
        {
            collection: 'orders',
            limit: 10
        }
    ]),

    connect(mapStateToProps)
)(Products)