import OrdersActionTypes from "./OrdersActionTypes"

const initialState = {
    placing: false,
    placed: false,
    cartItems: []
}

const OrdersReducer = (state = initialState, action) => {
    switch (action.type) {
        case OrdersActionTypes.PLACE_ORDER_START:
            return {
                ...state,
                placing: true
            }

        case OrdersActionTypes.PLACE_ORDER_SUCCESS:
            return {
                ...state,
                orderRef: action.payload.orderRef,
                placing: false,
                placed: true
            }

        case OrdersActionTypes.PLACE_ORDER_ERROR:
            return {
                ...state,
                placing: false
            }

        case OrdersActionTypes.ADD_ORDER_ITEMS:
            return {
                ...state,
                cartItems: action.payload.items
            }

        case OrdersActionTypes.CLEAR_ORDER:
            return {
                ...state,
                cartItems: [],
                placing: false,
                placed: false,
                orderRef: null
            }

        default:
            return state
    }
}

export default OrdersReducer