import React from 'react'
import FullStar from './FullStar'

const ProductRating = () => {
    return (
        <div className="product-summary__rating">
            <FullStar />
            <FullStar />
            <FullStar />
            <FullStar />
            <FullStar />
            <span className="product-summary__rating__reviews">{parseInt(Math.random() * 40)} Reviews</span>
        </div>
    )
}

export default ProductRating