import NotificationActionTypes from "./NotificationActionTypes"


const initialState = {
    data : []
}

const NotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case NotificationActionTypes.CREATE:
            let newState = {
                ...state,
                data : [...state.data, action.payload]
            }
            
            return newState

        case NotificationActionTypes.DELETE:
            return {
                ...state,
                data : state.data.filter(notification => notification.id !== action.payload.id)
            }
    
        default:
            return state
    }
}

export default NotificationReducer