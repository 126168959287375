import firebase from 'firebase/app'
import 'firebase/firebase-auth'
import 'firebase/firebase-firestore'

import firebaseConfig from '../config/firebase'

const app = firebase.initializeApp(firebaseConfig)

export const firebaseAuth = app.auth()

export const firestore = app.firestore()

firestore.enablePersistence().catch(function(err) {
    if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
    } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
    }
});

export default app

