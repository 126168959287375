// import ProductActionActionTypes from "./ProductActionTypes"

import ProductActionTypes from "./ProductActionTypes";

const initialState = {
    data: [
        {
            "id": 13,
            "name": "Jungle Oats 1kg",
            "desc": "“Jungle Oats, like other oats, contains beta-glucan, a fibre that has been known to assist in reducing the 'bad' blood cholesterol levels,” says Papadopoulos-Fenster. Soluble fiber from foods, such as Oats as part of a diet low in saturated fat and cholesterol, may reduce the risk of heart disease",
            "price": 315,
            "category": "groceries",
            "department": "cereals",
            "pictures": [
                "https://scontent.fhre1-1.fna.fbcdn.net/v/t1.0-9/s960x960/89580959_126005058972016_7582798970502512640_o.jpg?_nc_cat=107&_nc_sid=8024bb&_nc_oc=AQnaEirnun4APO3KQ96yrP-jGMELe4x8AdU8GCBNTIWSg0eSbQtGrn6ApETBjYiFA7Y&_nc_ht=scontent.fhre1-1.fna&_nc_tp=7&oh=7a34e1288f565df9d1dab2a66381fb22&oe=5E8D7B97"
            ],
            "tags": [
                "top rated"
            ],
            "qty": 178,
            "created_at": "2020-03-05 07:13:29",
            "updated_at": "2020-03-05 07:13:29",
            "seller": {
                "id": 1,
                "name": "TechBoss",
                "email": "vesta.hamill@example.net",
                "email_verified_at": "2020-03-05 07:13:29",
                "created_at": "2020-03-05 07:13:29",
                "updated_at": "2020-03-05 07:13:29"
            },
            "specs": [{
                "id": 1,
                "product_id": 1,
                "name": "Ingredient",
                "value": "100% whole grain pure oats",
                "created_at": "2020-03-05 07:13:29",
                "updated_at": "2020-03-05 07:13:29"
            }],
            "variations": [{
                "id": 1,
                "product_id": 1,
                "name": "flavour",
                "values": [
                    "original",
                    "chocolate"
                ],
                "created_at": "2020-03-05 07:13:29",
                "updated_at": "2020-03-05 07:13:29"
            }]
        }
    ]
}

const ProductsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ProductActionTypes.CREATE_PRODUCT:
            console.log("creating product")
            return state

        case ProductActionTypes.CREATE_PRODUCT_SUCCESS:
            console.log("creating product success", action.product)
            return state

        case ProductActionTypes.CREATE_PRODUCT_ERROR:
            console.log("creating product error")
            return state
        
        default:
            return state;
    }
}

export default ProductsReducer