import React, { Component } from 'react'
import { connect } from 'react-redux'

import Router from './Router'
import { loadApplication } from '../redux/application/ApplicationActions'
import Preloader from './pages/PreLoader'
// import SearchIndexer from './partials/SearchIndexer'


class App extends Component {

  componentDidMount = () => {
    this.props.loadApplication()
  }

  render = () => {
    return (
      this.props.loading ? 
      <Preloader /> : <React.Fragment>
          <Router />
          {/* <SearchIndexer /> */}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
    return {
        loading: state.application.loading
    }
}

const mapDispatchToProps = dispatch => ({
  loadApplication: () => dispatch(loadApplication())
})

export default connect(mapStateToProps, mapDispatchToProps)(App)