import numeral from 'numeral'

export const formatQuotient = price => {
    return numeral(parseInt(price / 100)).format('0,0')
}

export const formatRemainder = price => {
    return numeral(parseFloat(price % 100)).format('00')
}

export const format = price => {
    let first = formatQuotient(price)
    let last = formatRemainder(price)

    return `${first}.${last}`
}

