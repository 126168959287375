import NotificationActionTypes from "./NotificationActionTypes"
import {
    v4 as uuid
} from "uuid"


export const createNotification = (notification) => ({
    type: NotificationActionTypes.CREATE,
    payload: notification
})

export const deleteNotification = (notification) => ({
    type: NotificationActionTypes.DELETE,
    payload: notification
})

export const notify = (notification) => {
    notification.id = uuid()
    return dispatch => {
        dispatch(createNotification(notification))

        setTimeout(() => {
            dispatch(deleteNotification(notification))
        }, 5000)
    }
}