import React, { Component, Fragment } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { sendPasswordResetEmail } from '../../redux/auth/AuthenticationActions'
import { connect } from 'react-redux'
import Notifications from '../partials/Notifications'

class ForgetPassword extends Component {
    constructor(props){
        super(props)
        this.state = {
            email: ''
        }
    }

    handleChange = e => {
        let name = e.target.name
        let value = e.target.value

        this.setState(preveState => ({
            ...preveState,
            [name] : value
        }))
    }

    handleSubmit = e => {
        e.preventDefault()
        this.props.sendPasswordResetEmail(this.state.email)
    }

    render () {
        let {requestingResetEmail, error, auth} = this.props

        if (auth && auth.uid) {
            return (
                <Redirect to='/' />
            )
        }

        return (
            <Fragment>
                <div style={{padding: '0 1em', maxWidth: '30em', margin: 'auto'}}>
                    <h1 className="main-heading" style={{fontWeight: 600}}><span className="main-heading__left">F</span><span className="main-heading__right">orgot password</span></h1>

                    <div>
                        {error ? <p style={{padding: '1em 0', color: '#DD4B39'}}>{error.message}</p> : null}
                    </div>
                    <form method="POST" onSubmit={this.handleSubmit}>
                        <input type='email' name='email' value={this.state.email ?? ''} onChange={this.handleChange} placeholder='Your email' className="shadowed panel" style={{border: 'none', outline: 'none', display: 'block', width: '100%', fontSize: '1.3rem', padding: '0.8em', borderRadius: '0.5em'}} />
                        <button type='submit' className="btn btn--secondary shadowed" style={{display: 'block', marginTop: '1.3em', borderRadius: '0.5em', width: '100%', fontSize: '1.1rem'}}>{requestingResetEmail ? 'Requesting email...':'Request reset email'}</button>
                    </form>
                    <div style={{textAlign: 'center', fontSize: '1.2rem', margin: '5em 0'}}>Already have an account? <Link to="/login">Sign in!</Link></div>
                </div>
                <Notifications />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    requestingResetEmail: state.auth.requestingResetEmail,
    error : state.auth.error,
    auth: state.firebase.auth
})

const mapDispatchToProps = dispatch => ({
    sendPasswordResetEmail: email => dispatch(sendPasswordResetEmail(email))
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword)
