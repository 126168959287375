import React, { Component } from 'react'
import { connect } from 'react-redux'
import AppLayout from '../../layouts/AppLayout'
import { getFirestore } from 'redux-firestore'
import crossoutSvg from '../../../img/cross-out.svg'
import minusSvg from '../../../img/minus.svg'
import plusSvg from '../../../img/plus.svg'
import { format } from '../../../helpers'
import { Redirect } from 'react-router-dom'

class AdminOrderDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount(){
        getFirestore().collection('orders').doc(this.props.match.params.id).get()
            .then(order => {
                let promises = []
                order = order.data()
                order.items.forEach((item) => {
                    promises = [...promises, getFirestore().collection('products').doc(item.id).get()]
                })

                Promise.all(promises).then(products => {
                    let merged = products.map(product => {
                        return {
                            ...order.items.filter(i => i.id === product.id).reduce(i => i),
                            ...product.data()
                        }
                    })

                    let subTotal = merged.reduce((sum, product) => (product ? (product.price * product.qty) : 0) + sum, 0)
                    let shipping = merged.length > 0 ? order.shipping : 0
                    let tax = merged.length > 0 ? order.tax : 0
                    let total = subTotal + shipping + tax

                    order.items = merged
                    order.subTotal = subTotal
                    order.shipping = shipping
                    order.tax = tax
                    order.total = total

                    this.setState(prevState => ({
                        ...prevState,
                        order
                    }))
                })
            })
    }

    cartItem = ({product}) => {
        return (
            <div style={{backgroundColor: 'white', borderRadius: '0.5em', display: 'flex'}} className="shadowed">
                <img style={{borderTopLeftRadius: '0.5em', borderBottomLeftRadius: '0.5em', width: '5em', height: '7em', display: 'block'}}
                    src={product.images ? product.images[0]: 'https://via.placeholder.com/1280x900.png'} alt={product.name} />
                <div style={{padding: '0.5em 0.8em', marginTop: 0, flexGrow: 1, borderTopRightRadius: '0.5em', borderBottomRightRadius: '0.5em'}}>
                    <h4 style={{fontWeight: 500, whiteSpace: 'nowrap'}}>{product.name}</h4>
                    <p
                        style={{display: 'flex', justifyContent: 'space-between', fontSize: '0.8rem', fontWeight: 500, marginTop: '0.8em', color: 'var(--secondary)'}}>
                        <span>${format(product.price)} * {product.qty}</span> <span style={{marginTop: 0}}>${format(product.qty * product.price)}</span>
                    </p>
                    <div style={{marginTop: '0.8em'}}>
                        <button 
                            style={{display: 'inline-block', border: 'none', cursor: 'pointer', background: 'none', margin: 0}}
                            onClick={e => {e.preventDefault()}}>
                            <img style={{display: 'block', width: 18, height: 18, backgroundColor: 'var(--gray-400)', padding: 5, borderRadius: '50%'}} src={minusSvg} alt="decorative" />
                        </button>
                        <button 
                            style={{display: 'inline-block', border: 'none', cursor: 'pointer', background: 'none', marginTop: 0, marginLeft: '0.5em', marginRight: '0.6em'}}
                            onClick={e => {e.preventDefault();}}>
                            <img style={{display: 'block', width: 18, height: 18, backgroundColor: 'var(--gray-400)', padding:5, borderRadius: '50%'}} src={plusSvg} alt="decorative" />
                        </button>
                        <button 
                            style={{display: 'inline-block', border: 'none', cursor: 'pointer', background: 'none', margin: 0}} 
                            onClick={e => {e.preventDefault();}}>
                            <img style={{display: 'block', width: 18, height: 18, backgroundColor: 'var(--gray-400)', padding:5, borderRadius: '50%'}} src={crossoutSvg} alt="decorative" />
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    render(){
        let {isAdmin} = this.props

        if (!isAdmin) {
            return (
                <Redirect to='/' />
            )
        }
    
        if (!this.state.order) {
            return (
                <AppLayout>
                    <div className='dashboard'>
                        <div className='dashboard__left'>
                            <h2 className="main-heading" style={{margin: 0}}><span className="main-heading__left">Or</span><span className="main-heading__right">der Details</span></h2>

                            <div>Loading...</div>
                        </div>
                        <div className='dashboard__right'>
                            <h2 className="main-heading" style={{margin: 0}}><span className="main-heading__left">Qu</span><span className="main-heading__right">ick Links</span></h2>
                            <div style={{display: 'flex', flexWrap: 'wrap', margin: '2em -0.5em 0'}}>
                                <a href=' ' className='shadowed panel' style={{display: 'block', backgroundColor: '#fff', marginTop: '0.5em', width: '100%', padding: '0.5em 1em', fontWeight: '400'}}>Create New Product</a>
                                <a href=' ' className='shadowed panel' style={{display: 'block', backgroundColor: '#fff', marginTop: '0.5em', width: '100%', padding: '0.5em 1em', fontWeight: '400'}}>Algolia Search Overview</a>
                                <a href=' ' className='shadowed panel' style={{display: 'block', backgroundColor: '#fff', marginTop: '0.5em', width: '100%', padding: '0.5em 1em', fontWeight: '400'}}>Firebase Usage & Billing</a>
                            </div>
                        </div>
                    </div>
                </AppLayout>
            )
        }

        let {order} = this.state

        return (
            <AppLayout>
                <div className='dashboard'>
                    <div className='dashboard__left'>
                        <h2 className="main-heading" style={{margin: 0}}><span className="main-heading__left">Or</span><span className="main-heading__right">der Details</span></h2>
    
                        <div className="checkout" style={{marginTop: '2em'}}>
                            <form className="panel shadowed" style={{padding: '1em', backgroundImage: 'linear-gradient(to bottom, #fff 10em, var(--gray-100))'}} method="post" onSubmit={e => console.log(e)}>
                                <div className="section-title">
                                    <h3 className="section-title__heading">Addresses</h3>
                                    <div className="section-title__desc">Client's shipping details</div>
                                    <div className="hr"></div>
                                </div>
                                <div style={{}}>
                                    <div style={{flexBasis: '50%'}}>
                                        <p>{order.fullname}</p>
                                        <p>{order.street}</p>
                                        <p>{order.surbarb}</p>
                                        <p>{order.city}, ZW</p>
                                        <p>{order.phone}</p>
                                    </div>
                                    <div style={{flexBasis: '50%', marginTop: '3em', textAlign: 'right'}}>
                                        <p>TechBoss Inc</p>
                                        <p>Office 201 Bulawayo Center</p>
                                        <p>Bulawayo CBD</p>
                                        <p>Bulawayo, ZW</p>
                                        <p>+263782632563</p>
                                    </div>
                                </div>
        
                                <div className="section-title" style={{marginTop: '3em'}}>
                                    <h3 className="section-title__heading">Items</h3>
                                    <div className="section-title__desc">Items ordered</div>
                                    <div className="hr"></div>
                                </div>

                                {order.items.map(item => <this.cartItem key={item.id} product={item} />)}
                                
                                <div className="hr" style={{margin: '1.5em 0'}}></div>
    
                                <div className="panel shadowed" style={{padding: '0.8em', marginBottom:'2em', fontWeight: 400, fontSize:' 0.95rem',     border: '1px solid var(--gray-100)'}}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Sub Total</span> <span style={{marginTop: 0}}>${format(order.subTotal)}
                                        </span>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.4em' }}>
                                        <span>Shipping</span> <span style={{marginTop: 0}}>${format(order.shipping)}</span>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.4em' }}>
                                        <span>Tax</span> <span style={{marginTop: 0}}>${format(order.tax)}</span>
                                    </div>
                                    <div className="hr"></div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.6em' }}>
                                        <span>Grand Total</span> <span style={{marginTop: 0}}>${format(order.total)}</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='dashboard__right'>
                        <h2 className="main-heading" style={{margin: 0}}><span className="main-heading__left">Qu</span><span className="main-heading__right">ick Links</span></h2>
                        <div style={{display: 'flex', flexWrap: 'wrap', margin: '2em -0.5em 0'}}>
                            <a href=' ' className='shadowed panel' style={{display: 'block', backgroundColor: '#fff', marginTop: '0.5em', width: '100%', padding: '0.5em 1em', fontWeight: '400'}}>Create New Product</a>
                            <a href=' ' className='shadowed panel' style={{display: 'block', backgroundColor: '#fff', marginTop: '0.5em', width: '100%', padding: '0.5em 1em', fontWeight: '400'}}>Algolia Search Overview</a>
                            <a href=' ' className='shadowed panel' style={{display: 'block', backgroundColor: '#fff', marginTop: '0.5em', width: '100%', padding: '0.5em 1em', fontWeight: '400'}}>Firebase Usage & Billing</a>
                        </div>
                    </div>
                </div>
            </AppLayout>
        )
    }
}

const mapStateToProps = state => ({
    isAdmin: state.firebase.profile.isAdmin,
})

export default connect(mapStateToProps)(AdminOrderDetails)