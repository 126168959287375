const AuthenticationActionTypes = {
    SET_USER: 'SET_USER',
    SIGNING_UP: 'SIGNING_UP',
    SIGNUP_ERROR: 'SIGNUP_ERROR',
    SIGNED_UP: 'SIGNED_UP',
    EMAIL_VERIFICATION_SENT : 'EMAIL_VERIFICATION_SENT',
    LOGGING_IN: 'LOGGING_IN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGGING_OUT: 'LOGGING_OUT',
    LOGGED_OUT: 'LOGGED_OUT',
    LOGOUT_ERROR: 'LOGOUT_ERROR',
    REQUESTING_RESET_EMAIL: 'REQUESTING_RESET_EMAIL',
    REQUEST_RESET_EMAIL_SUCCESS: 'REQUEST_RESET_EMAIL_SUCCESS',
    REQUEST_RESET_EMAIL_ERROR: 'REQUEST_RESET_EMAIL_ERROR'
}

export default AuthenticationActionTypes