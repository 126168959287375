import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import { connect } from 'react-redux'

import {closeSidebar} from '../../redux/sidebar/SidebarActions'
import {closeCart} from '../../redux/cart/CartActions'
import qs from 'qs'

class SearchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query : ''
        }
    }

    handleChange = event => {
        let {value} = event.target
        this.setState((prevState, props) => ({
            ...prevState,
            query : value
        }))
    }

    handleSubmit = event => {
        event.preventDefault()
        
        if (this.props.isShoppingCartOpen){
            this.props.closeShoppingCart()
        }

        if (this.props.isSideBarOpen){
            this.props.closeSidebar()
        }

        this.props.history.push(`/search?${qs.stringify(this.state)}`)
    }

    render() {
        return (
            <div className="search-bar">
                <form className="search-bar--main" onSubmit={this.handleSubmit}>
                <input style={{outline: 'none', fontSize: '1rem', paddingLeft:'1.5em'}} name="query" type="text" value={this.state.query} onChange={this.handleChange} placeholder="Search on TechBoss" />
            </form>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeSidebar: () => dispatch(closeSidebar()),
        closeShoppingCart: () => dispatch(closeCart())
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        isSideBarOpen : state.sidebar.isOpen,
        isShoppingCartOpen : state.cart.isOpen
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchForm))
