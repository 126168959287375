import React from 'react'
import {Link} from 'react-router-dom'

import SearchForm from '../partials/SearchForm'
import {openSidebar} from '../../redux/sidebar/SidebarActions'
import {openCart} from '../../redux/cart/CartActions'

import menuSvg from '../../img/menu.svg'
import supermarketSvg from '../../img/supermarket.svg'
import accountSvg from '../../img/account.svg'
import { connect } from 'react-redux'

const TopBar = ({openSidebar, openCart, totalItems}) => {
    return (
        <React.Fragment>
            <div className="topbar">
                <div style={{display: 'flex', alignItems:'center'}}>
                    <img id="nav-toggle" style={{width: "1.4em", height: "80%"}}
                        src={menuSvg} alt=" " onClick={() => openSidebar()}/>
                    <h1 className="logo--mobile">
                        <Link to="/">
                            <span className="logo-left">Tech</span><span className="logo-right">Boss</span>
                        </Link>
                    </h1>
                </div>
                <div className="topbar__right">
                    <div className="topbar-search">
                    <SearchForm />
                    </div>
                    <a id="cartOpenButton" style={{display: "block", width: '1.8em', margin: '0 0.8em', position: 'relative'}}
                        href="/" onClick={(e) => {e.preventDefault(); openCart()}}><img src={supermarketSvg} alt="deco" />
                        <span style={{position: 'absolute', fontWeight: 600, top: -10, fontSize: '0.8rem'}}>{totalItems}</span>
                    </a>

                    <a style={{display: 'block', width: '1.8em', margin: '0 0.8em'}} href="/"><img src={accountSvg}
                            alt="deco" /></a>
                </div>
            </div>
            <div style={{margin: 0}} className="hr"></div>
        </React.Fragment>
    )
}

const mapDispatchToProps = dispatch => ({
    openSidebar: () => dispatch(openSidebar()),
    openCart: () => dispatch(openCart())
})

const mapStateToProps = state => {
    let total = state.cart.items.length > 0 ? state.cart.items.map(i => i.qty).reduce((el, sum) => el + sum, 0) : 0;
    return {
        totalItems : total
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar)