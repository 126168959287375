import React, {Component} from 'react'
import {v4 as uuid} from 'uuid'

import { addToCart } from '../../redux/cart/CartActions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class AddToCartForm extends Component {

    constructor(props){
        super(props)
        this.state = {
            qty : 1,
            variations : []
        }
        this.form = React.createRef()
    }

    // List of default number of items a user can select
    quantities = [1,2,3,4,5]

    // Set the quantity to add to cart
    setQuantity = val => {
        this.setState(prevState => ({
            ...prevState,
            qty: val
        }))
    }

    // Set the variation to a given value
    setVariation = (name, value) => {
        this.setState(prevState => {
            let newState = {
                ...prevState
            }

            let variations = prevState.variations
            if (variations.length > 0) {
               variations = variations.filter(v => v.name !== name)
            } 

            newState.variations = [...variations, {name , value}]

            return newState
        })
    }

    // Check if the variation with the given name is selected
    isSelected = (variation, val) => {
        let inState = this.state.variations.filter(sv => sv.name === variation.name)

        if (inState.length > 0) {
            return inState[0].value === val
        }
    }

    // Get the default selected variations
    getVariations = () => this.props.product.variations && this.props.product.variations.length > 0 ? this.props.product.variations.map(v => ({name: v.name, value: v.values[0]})) : []

    // Set the default state for the component
    setDefaultState = () => {
        this.setState((prevState) => {
            return {
                qty: 1,
                id: this.props.product.id,
                variations: this.getVariations()
            }
        })
    }

    // Component updates when react router reloads the data or when adding stuff to cart
    componentDidUpdate(prevProps, prevState){
        if (prevProps.product.id !== this.props.product.id) {
            this.setDefaultState()
        }
    }

    // Called during initial render
    componentDidMount(){
        this.setDefaultState()
    }

    // Add an item to the cart
    addToCart = () => {
        this.props.addToCart(this.state)
        this.setDefaultState()
    }

    // Render the component
    render ({product} = this.props){
        return (
            <div ref={this.form}>
            {product.variations && product.variations.map(variation => 
                <React.Fragment key={uuid()}>
                    <p className="product-options__heading">Pick a {variation.name}</p>
                    <ul className="product-options__list">
                        {variation.values && variation.values.map((val, index )=> 
                            <li key={uuid()} 
                                className={`product-option ${this.isSelected(variation, val) ? 'selected' : ''}`}
                                onClick={() => this.setVariation(variation.name, val)} >{val}
                            </li>
                        )}
                    </ul>
                </React.Fragment>
            )}
            <p className="product-options__heading">How many do you want?</p>
            <div style={{maxWidth: '20em'}}>
                <ul className="product-options__list">
                   {this.quantities.map(q => 
                        <li key={uuid()} className={`product-option ${this.state.qty === q ? 'selected' : ''}`} onClick={() => {this.setQuantity(q)}}>
                            {q}
                        </li>)
                    }
                </ul>
                <div style={{marginTop: '1em'}}></div>
                <button type="submit" className="btn btn--secondary" onClick={() => {this.addToCart()}}>Add to cart</button>
                <button type="submit" style={{marginLeft: 10}} className="btn btn--primary">Buy now</button>
            </div>
        </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addToCart : item => dispatch(addToCart(item))
    }
}

export default withRouter(connect(null, mapDispatchToProps)(AddToCartForm))