import algoliasearch from 'algoliasearch';
import React, { useEffect } from 'react'
import { InstantSearch, SearchBox } from 'react-instantsearch-dom';
import AppLayout from '../layouts/AppLayout';
import { useLocation, Link } from 'react-router-dom';
import CustomInfiniteHits from '../partials/CustomInfiniteHits';

const searchClient = algoliasearch('BSWPJIFL3D', 'b094bd5d3ac030ba3cef7bfc7afc2259');

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Search = () => {
    const query = useQuery()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [query.query])

    return (
        <InstantSearch searchClient={searchClient} indexName="products">
            <AppLayout>
            
                <div className="homepage-search" style={{marginTop: '1em', width: '100%'}}>
                    <div className="shadowed homepage-search__bar" style={{ borderRadius: '0.5em'}}>
                        <SearchBox searchAsYouType={false} defaultRefinement={query.get('query')} showLoadingIndicator={true} />
                    </div>

                    <div className="overview overview--search">
                        <Link to="/groceries" className="shadowed overview__item">
                            <img src='https://scontent.fhre3-1.fna.fbcdn.net/v/t1.0-0/p180x540/89840578_1632438566894067_1422907804577955840_o.jpg?_nc_cat=111&_nc_sid=8024bb&_nc_oc=AQmdap1j_iZMhvyx4YKAJfqG831AuOAt7nzjuloOdl3EC-DS5BGo3bICjDK3WY0q7Do&_nc_ht=scontent.fhre3-1.fna&_nc_tp=6&oh=c3974ab5b83f6e40047cf39c26d9782a&oe=5E990ECF' alt=" "/>
                            <p>Groceries</p>
                        </Link>
                        <Link to="/physicals" className="shadowed overview__item">
                            <img src='https://scontent.fhre3-1.fna.fbcdn.net/v/t1.0-9/89947418_1632434520227805_8450215835527544832_n.jpg?_nc_cat=105&_nc_sid=8024bb&_nc_oc=AQkRjWwA78AqwS3iUBR4n77ZchI2PJtJ-N1JlSwt9NtYy2O3DScC-K58V9Rm--hsfFA&_nc_ht=scontent.fhre3-1.fna&oh=8c35521214543530bade2cba14c95450&oe=5E988BBD' alt=" "/>
                            <p>Physicals</p>
                        </Link>
                        <Link to="/digitals" className="shadowed overview__item">
                            <img src='https://scontent.fhre3-1.fna.fbcdn.net/v/t1.0-0/p180x540/89924290_1632440123560578_3635673744296378368_o.jpg?_nc_cat=110&_nc_sid=8024bb&_nc_oc=AQk0m7pfZBBfQBRq2bV-PONbMPKsHMnLgaw413z6FKqtzV8Hs8zHlmi-HsJW_boR1Ms&_nc_ht=scontent.fhre3-1.fna&_nc_tp=6&oh=d176fd6dc1a3280e788c7b04e5d0d416&oe=5E9A57CA' alt=" "/>
                            <p>Digitals</p>
                        </Link>
                </div>
                </div>

                <CustomInfiniteHits />
            </AppLayout>
        </InstantSearch>
    )
}

export default Search