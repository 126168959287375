import React from 'react'
import { connect } from 'react-redux'

import CartItems from './CartItems'

import {closeCart} from '../../redux/cart/CartActions'

import crossoutSvg from '../../img/cross-out.svg'
import { Link } from 'react-router-dom'
import CartSummary from './CartSummary'


const ShoppingCart = ({isOpen, closeShoppingCart, items}) => {
    return (
        <div id="cart" className={`shadowed cart ${isOpen ? 'open':''}`}>
        <div className="section-title">
            <h3 className="section-title__heading">Shopping cart</h3>
            <div className="section-title__desc">See what's in your basket...</div>
            <div className="hr"></div>
        </div>
        <img id="cartCloseButton"
            style={{width: '2em', height: '2em', position: 'absolute', right: '1em', top: '1em', cursor: 'pointer', backgroundColor: 'var(--gray-400)', borderRadius: '50%', padding: '0.5em'}}
            src={crossoutSvg} alt="Shopping drawer close button" onClick={() => {closeShoppingCart()}}/>


        <CartItems items={items} />

        <div className="hr"></div>

        <CartSummary />
        {items.length > 0 && <Link to={'/checkout'} className="btn btn--secondary shadowed" style={{width: '100%', display: 'inline-block', textAlign: 'center', color: 'var(--light-text)'}}>Go to checkout</Link>}
    </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isOpen : state.cart.isOpen,
        items : state.cart.items
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeShoppingCart: () => dispatch(closeCart())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart)
  