import React from 'react'
import {Link, NavLink} from 'react-router-dom'
import { connect } from 'react-redux'

import userSvg from '../../img/user.svg'
import downloadSvg from '../../img/download.svg'
import boxSvg from '../../img/box.svg'
import grocerySvg from '../../img/groceries.svg'
import paySvg from '../../img/pay.svg'
import orderSvg from '../../img/order.svg'
import helpSvg from '../../img/question.svg'
import returnSvg from '../../img/return.svg'
import SearchForm from './SearchForm'
import { signOut } from '../../redux/auth/AuthenticationActions'

const Sidebar = ({isOpen, displayName, uid, signOut}) => {
    return (
        <div className={`sidebar shadowed ${isOpen ? 'open' : ''}`} id="sidebar">
            <div className="sidebar_content">

                <div className="logo">
                    <h1>
                        <Link to="/" style={{fontWeight: 500}}>
                            <span className="logo-left">Tech</span><span className="logo-right">Boss</span>
                        </Link>
                    </h1>
                </div>
                <div className="hr"></div>

                <div className="user-acc-summary">
                    <img className="user-acc-summary__avatar" src={userSvg} alt="user account" />
                    <div className="user-acc-summary__name">
                        <p>{displayName ?? 'Guest User' }</p>
                        {uid ? <Link to="/logout" onClick={e => {e.preventDefault(); signOut()}}>Sign out</Link> : <Link to="/login">Please log in</Link>}
                    </div>
                </div>
                <div className="hr"></div>

                <div className="mobile-search-container" style={{paddingTop: '0.5em', paddingBottom:'0.5em'}}>
                <SearchForm />
                </div>
                <div className="hr"></div>
                <nav className="nav">
                    <div className="nav__group">
                        <h2 className="nav__group__title">
                            Markets
                        </h2>
                        <ul className="nav__items">
                            <li className="nav__item">
                                <NavLink to="/digitals"><img src={downloadSvg} alt="groceries svg icon" /><span>Digitals</span></NavLink>
                            </li>
                            <li className="nav__item">
                                <NavLink to="/physicals"><img src={boxSvg} alt="groceries svg icon" /><span>Physicals</span></NavLink>
                            </li>
                            <li className="nav__item">
                                <NavLink to="/groceries"><img src={grocerySvg} alt="groceries svg icon" /><span>Groceries</span></NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="nav__group">
                        <h2 className="nav__group__title">
                            Sellers
                        </h2>
                        <ul className="nav__items">
                            <li className="nav__item">
                                <NavLink to="/orders"><img src={orderSvg} alt="groceries svg icon" /><span>Orders</span></NavLink>
                            </li>
                            <li className="nav__item">
                                <NavLink to="/shops"><img src={paySvg} alt="groceries svg icon" /><span>Products</span></NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="nav__group">
                        <h2 className="nav__group__title">
                            More
                        </h2>
                        <ul className="nav__items">
                            <li className="nav__item">
                                <NavLink to="/help"><img src={helpSvg} alt="groceries svg icon" /><span>Help & FAQ's</span></NavLink>
                            </li>
                            <li className="nav__item">
                                <NavLink to="/return-policy"><img src={returnSvg} alt="groceries svg icon" /><span>Return Policy</span></NavLink>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        displayName: state.auth.displayName,
        uid: state.auth.uid,
        isOpen : state.sidebar.isOpen
    }
}

const mapDispatchToProps = dispatch => ({
    signOut : () => dispatch(signOut())
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)