import React from 'react'
import AppLayout from '../layouts/AppLayout'

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ProductSection from '../partials/ProductSection';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux'
import HomepageSearchForm from '../partials/HomepageSearchForm';

const Home = ({groceries, physicals, recommendations, trendingItems}) => {
    document.title = `TechBoss | The best online shopping experience`

    return (
        <AppLayout>
            <div className='banner'>
                <h2>What are you <br />looking for?</h2>
            </div>

            <div className="homepage-search">
                <HomepageSearchForm />

                <div className="overview">
                    <Link to="/groceries" className="shadowed overview__item">
                        <img src='https://scontent.fhre3-1.fna.fbcdn.net/v/t1.0-0/p180x540/89840578_1632438566894067_1422907804577955840_o.jpg?_nc_cat=111&_nc_sid=8024bb&_nc_oc=AQmdap1j_iZMhvyx4YKAJfqG831AuOAt7nzjuloOdl3EC-DS5BGo3bICjDK3WY0q7Do&_nc_ht=scontent.fhre3-1.fna&_nc_tp=6&oh=c3974ab5b83f6e40047cf39c26d9782a&oe=5E990ECF' alt=" "/>
                        <p>Groceries</p>
                    </Link>
                    <Link to="/physicals" className="shadowed overview__item">
                        <img src='https://scontent.fhre3-1.fna.fbcdn.net/v/t1.0-9/89947418_1632434520227805_8450215835527544832_n.jpg?_nc_cat=105&_nc_sid=8024bb&_nc_oc=AQkRjWwA78AqwS3iUBR4n77ZchI2PJtJ-N1JlSwt9NtYy2O3DScC-K58V9Rm--hsfFA&_nc_ht=scontent.fhre3-1.fna&oh=8c35521214543530bade2cba14c95450&oe=5E988BBD' alt=" "/>
                        <p>Physicals</p>
                    </Link>
                    <Link to="/digitals" className="shadowed overview__item">
                        <img src='https://scontent.fhre3-1.fna.fbcdn.net/v/t1.0-0/p180x540/89924290_1632440123560578_3635673744296378368_o.jpg?_nc_cat=110&_nc_sid=8024bb&_nc_oc=AQk0m7pfZBBfQBRq2bV-PONbMPKsHMnLgaw413z6FKqtzV8Hs8zHlmi-HsJW_boR1Ms&_nc_ht=scontent.fhre3-1.fna&_nc_tp=6&oh=d176fd6dc1a3280e788c7b04e5d0d416&oe=5E9A57CA' alt=" "/>
                        <p>Digitals</p>
                    </Link>
                </div>
            </div>

            <h2 className="main-heading"><span className="main-heading__left">Di</span><span className="main-heading__right">scover TechBoss</span></h2>


            <div className="insights-container">
                <div className="shadowed panel"><h1>TechBoss</h1></div>
                <ul className="insights">
                    <li className="insight">
                        <h4><a href=" ">Same day delivery.</a></h4>
                        <p>TechBoss delivers your orders within 12 hours of purchase.</p>
                    </li>
                    <li className="insight">
                        <h4><a href=" ">10+ payment methods.</a></h4>
                        <p>Pay with Visa/MasterCard, EcoCash, OneMoney, Paypal or Skrill.</p>
                    </li>
                    <li className="insight">
                        <h4><a href=" ">Awesome return policy.</a></h4>
                        <p>You can return items to TechBoss if you are not satisfied.</p>
                    </li>
                </ul>
                <ul className="insights">
                    <li className="insight">
                        <h4><a href=" ">Value for money.</a></h4>
                        <p>Good quality items at unbeatable prices you can always trust.</p>
                    </li>
                    <li className="insight">
                        <h4><a href=" ">Refer friends, Get rewarded.</a></h4>
                        <p>Get credit by referring others to TechBoss. Use these credits to buy on TechBoss.</p>
                    </li>
                    <li className="insight">
                        <h4><a href=" ">Helping you buy better.</a></h4>
                        <p>Use other shoppers' reviews to make good buying decisions.</p>
                    </li>
                </ul>
            </div>
            <h2 className="main-heading"><span className="main-heading__left">No</span><span className="main-heading__right">w lets get shopping</span></h2>

            <ProductSection 
                title={`Recently added items`} 
                description='The latest products in store today' 
                products={physicals}
            />

            <ProductSection 
                title={`Top selling groceries`} 
                description='See what everyone around you is buying' 
                products={groceries}
            />

            <ProductSection 
                title={`Recommendations from us`} 
                description='You may be interested in these items' 
                products={recommendations}
            />


            <ProductSection 
                title={`Trending items`} 
                description='Grab one for yourself while stock lasts' 
                products={trendingItems}
            />
        </AppLayout>
    )
}

export default compose(
    firestoreConnect(props => [
        {
            collection: 'products',
            limit : 4,
            where: [
                ['recentlyAdded', '==', true]
            ],
            storeAs: 'physicals'
        },
        {
            collection: 'products',
            limit : 4,
            where: [
                ['department', '==', 'groceries']
            ],
            storeAs: 'groceries'
        },
        {
            collection: 'products',
            limit : 4,
            where: [
                ['recommended', '==', true]
            ],
            storeAs: 'recommendations'
        },
        {
            collection: 'products',
            limit : 4,
            where: [
                ['trending', '==', true]
            ],
            storeAs: 'trendingItems'
        }
    ]),

    connect((state) => ({
        groceries: state.firestore.data['groceries'],
        recommendations: state.firestore.data['recommendations'],
        trendingItems: state.firestore.data['trendingItems'],
        physicals: state.firestore.data['physicals']
    }))
)(Home)
   