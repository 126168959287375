import React from 'react'

const Preloader = () => {
  return (
    <div style={{ backgroundColor: 'var(--secondary)', width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
  )
}

export default Preloader
