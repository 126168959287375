import React from 'react'
import ProductList from './ProductList'

const ProductSection = ({title, description, products}) => {
    return (
        <section>
            <div className="section-title" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div>
                    <h3 className="section-title__heading">{title}</h3>
                    <div className="section-title__desc">{description}</div>
                </div>
                <div style={{margin: 0, fontWeight: 600, marginRight: '0.7em', marginLeft: '0.5em'}}>
                    <a href=" " style={{display: 'block'}}>&#8592;</a>
                    <a href=" " style={{display: 'block', marginTop: '0.2em'}}>&#8594;</a>
                </div>
            </div>
            <div className="hr"></div>
            <div className="product-row">
                <ProductList products={products} />     
            </div>
        </section>
    )
}

export default ProductSection