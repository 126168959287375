import React from 'react'
import ProductSummary from './ProductSummary'

const ProductList = ({products}) => {

    if (products) {
        products = Object.keys(products).map(key => ({
            id: key,
            ...products[key]
        }))

        return (
            <React.Fragment>
                {products && products.map(product => <ProductSummary key={product.id} productId={product.id} />)}
            </React.Fragment>
        )
    }

    return (
        <div style={{height: '10em'}}>
            List loading...
        </div>
    )
    
}

export default ProductList