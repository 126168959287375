import React from 'react'
import AppLayout from '../layouts/AppLayout'
import ProductSection from '../partials/ProductSection'
import _ from 'lodash'
import {connect} from 'react-redux'

const DigitalsHome = ({recommendedProducts}) => {
    return (
        <AppLayout>
            <div className="panel shadowed" style={{padding: '1em', color: 'var(--gray-700)', marginBottom: '3em'}}>
                <div>
                    <h3 style={{fontSize: '2rem'}}><span style={{ fontWeight: '500', color: 'var(--secondary)'}}>25,000<span style={{fontWeight:300, fontSize: '1rem'}}>+</span></span> <span style={{fontWeight: 300, fontSize: '1.8rem'}}>digital elements</span></h3>
                    <div className="section-title__desc"style={{marginTop: '0.2em'}}>Photos, music, graphics, templates for websites and more</div>
                </div>
            </div>


            <ProductSection 
                title='Trending songs' 
                description='Something amazing you may fall in love with' 
                products={recommendedProducts}
            />

            <ProductSection 
                title='Trending songs' 
                description='Something amazing you may fall in love with' 
                products={recommendedProducts}
            />

            <ProductSection 
                title='Trending songs' 
                description='Something amazing you may fall in love with' 
                products={recommendedProducts}
            />

            <ProductSection 
                title='Trending songs' 
                description='Something amazing you may fall in love with' 
                products={recommendedProducts}
            />

            <ProductSection 
                title='Trending songs' 
                description='Something amazing you may fall in love with' 
                products={recommendedProducts}
            />
        </AppLayout>
    )
}

const mapStateToProps = state => {
    return {
        recommendedProducts : _.sampleSize(state.products.data, 4),
    }
}

export default connect(mapStateToProps)(DigitalsHome)