import React, { Component } from 'react'
import SmallProduct from './SmallProduct'
import { getFirestore } from 'redux-firestore'
import { format } from '../../helpers'
import { Link } from 'react-router-dom'

class OrderRow extends Component {

    constructor(props) {
        super(props)
        this.state = {
            subTotal: 0,
            shipping: 0,
            tax: 0,
            total: 0
        }
    }

    componentDidMount(){
        let {order} = this.props
        let promises = []
        order.items.forEach((item) => {
            promises = [...promises, getFirestore().collection('products').doc(item.id).get()]
        })

        Promise.all(promises).then(products => {
            let merged = products.map(product => {
                return {
                    ...order.items.filter(i => i.id === product.id).reduce(i => i),
                    ...product.data()
                }
            })

            let subTotal = merged.reduce((sum, product) => (product ? (product.price * product.qty) : 0) + sum, 0)
            let shipping = merged.length > 0 ? order.shipping : 0
            let tax = merged.length > 0 ? order.tax : 0
            let total = subTotal + shipping + tax

            this.setState(prevState => ({
                ...prevState,
                subTotal,
                shipping,
                tax,
                total
            }))
        })
    }

    OrderStatus = status => {
        switch (status) {
            case 'pending':
                return (
                    <span style={{backgroundColor: '#fd7e14', color: '#fff', padding: '0.1em 0.5em', borderRadius: '0.2em', fontWeight: 400}}>{status}</span>
                )

            case 'cancelled':
                return (
                    <span style={{backgroundColor: '#e65252', color: '#fff', padding: '0.1em 0.5em', borderRadius: '0.2em', fontWeight: 400}}>{status}</span>
                )

            case 'placed':
                return (
                    <span style={{backgroundColor: '#5bc0de', color: '#fff', padding: '0.1em 0.5em', borderRadius: '0.2em', fontWeight: 400}}>{status}</span>
                )

            case 'paid':
                return (
                    <span style={{backgroundColor: '#5bc0de', color: '#fff', padding: '0.1em 0.5em', borderRadius: '0.2em', fontWeight: 400}}>{status}</span>
                )

            default:
                return (
                    <span>{status}</span>
                )
        }
    }

    render(){

        let {order} = this.props
        let ecocashRate = 38

        return (
            <tr key={order.id} style={{fontWeight: 400}}>
                <td title={`${order.street} ${order.surbarb} ${order.city}`} style={{fontSize:'1.05rem' , padding: '1em 2em 1em 0', borderBottom: '1px solid var(--gray-100)', whiteSpace: 'nowrap'}}>{order.fullname} <br /> <span style={{fontSize: '0.8rem', color: 'var(--secondary)', fontWeight: 400}}>{order.phone}</span></td>
                <td style={{borderBottom: '1px solid var(--gray-100)', padding: '1em 2em 1em 0', display: 'flex'}}>
                    {
                        order.items.map((item, key) => {
                            return key <= 10 ? <SmallProduct key={item.id} item={item} /> : null
                        })
                    }
                </td>
                <td title={`ZWL $${format(this.state.total * ecocashRate)}`} style={{borderBottom: '1px solid var(--gray-100)', padding: '1em 2em 1em 0', fontWeight: 400}}>${format(this.state.total)}</td>
                <td style={{borderBottom: '1px solid var(--gray-100)', padding: '1em 2em 1em 0'}}>{ this.OrderStatus(order.status) }</td>
                <td style={{borderBottom: '1px solid var(--gray-100)', padding: '1em 2em 1em 0'}}><Link to={`/admin/orders/${order.id}`}>View</Link></td>
            </tr>
        )
    }
}

export default OrderRow