import React from 'react'

import {format} from '../../helpers'
import {deleteFromCart, updateItem} from '../../redux/cart/CartActions'

import crossoutSvg from '../../img/cross-out.svg'
import minusSvg from '../../img/minus.svg'
import plusSvg from '../../img/plus.svg'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'

const CartItem = ({id, qty, deleteFromCart, updateItem, product}) => {

    if (!isLoaded(product)) {
        return (
            <div>
                Cart item loading...
            </div>
        )
    }

    if (isEmpty(product)){
        return (
            <div>
                Product not found
            </div>
        )
    }

    let itemPrice = product.price
    let total = qty * itemPrice
    
    return (
        <div style={{backgroundColor: 'white', borderRadius: '0.5em', display: 'flex'}} className="shadowed">
            <img style={{borderTopLeftRadius: '0.5em', borderBottomLeftRadius: '0.5em', width: '5em', height: '7em', display: 'block'}}
                src={product.images ? product.images[0]: 'https://via.placeholder.com/1280x900.png'} alt={product.name} />
            <div style={{padding: '0.5em 0.8em', marginTop: 0, flexGrow: 1, borderTopRightRadius: '0.5em', borderBottomRightRadius: '0.5em'}}>
                <h4 style={{fontWeight: 500, whiteSpace: 'nowrap'}}>{product.name}</h4>
                <p
                    style={{display: 'flex', justifyContent: 'space-between', fontSize: '0.8rem', fontWeight: 500, marginTop: '0.8em', color: 'var(--secondary)'}}>
                    <span>${format(itemPrice)} * {qty}</span> <span style={{marginTop: 0}}>${format(total)}</span>
                </p>
                <div style={{marginTop: '0.8em'}}>
                    <button 
                        style={{display: 'inline-block', border: 'none', cursor: 'pointer', background: 'none', margin: 0}}
                        onClick={e => {e.preventDefault(); updateItem(id, qty - 1)}}>
                        <img style={{display: 'block', width: 18, height: 18, backgroundColor: 'var(--gray-400)', padding: 5, borderRadius: '50%'}} src={minusSvg} alt="decorative" />
                    </button>
                    <button 
                        style={{display: 'inline-block', border: 'none', cursor: 'pointer', background: 'none', marginTop: 0, marginLeft: '0.5em', marginRight: '0.6em'}}
                        onClick={e => {e.preventDefault(); updateItem(id, qty + 1)}}>
                        <img style={{display: 'block', width: 18, height: 18, backgroundColor: 'var(--gray-400)', padding:5, borderRadius: '50%'}} src={plusSvg} alt="decorative" />
                    </button>
                    <button 
                        style={{display: 'inline-block', border: 'none', cursor: 'pointer', background: 'none', margin: 0}} 
                        onClick={e => {e.preventDefault(); deleteFromCart(id)}}>
                        <img style={{display: 'block', width: 18, height: 18, backgroundColor: 'var(--gray-400)', padding:5, borderRadius: '50%'}} src={crossoutSvg} alt="decorative" />
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        deleteFromCart: (itemId) => dispatch(deleteFromCart(itemId)),
        updateItem: (itemId, qty) => dispatch(updateItem(itemId, qty))
    }
}

export default compose(
    firestoreConnect(ownProps => [
        {
            collection: 'products',
            doc : ownProps.itemId,
            storeAs: `products/${ownProps.itemId}`
        }
    ]),

    connect((state, ownProps) => {
        let product = state.firestore.data[`products/${ownProps.itemId}`]
        let item = state.cart.items.filter(item => item.id === ownProps.itemId).reduce(i => i)

        return {
            ...item,
            product
        }
    }, mapDispatchToProps)
)(CartItem)