import React from 'react'
import { format } from '../../helpers'
import { connect, useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

const CartSummary = ({items, shippingCost, taxCost}) => {

     let connections = items.map(item => ({
        collection: 'products',
        doc: item.id,
        storeAs: `products/${item.id}`
    }))

    useFirestoreConnect(connections)

    let products = useSelector(state => items.map(item => {
        return {
            ...item,
            ...state.firestore.data[`products/${item.id}`]
        }
    }))
    
    let subTotal = products.reduce((sum, product) => (product ? (product.price * product.qty) : 0) + sum, 0)
    let shipping = items.length > 0 ? shippingCost : 0
    let tax = items.length > 0 ? taxCost : 0
    let total = subTotal + shipping + tax

    return (
        <div className="panel shadowed" style={{padding: '0.8em', marginBottom:'2em', fontWeight: 400, fontSize:' 0.95rem', border: '1px solid var(--gray-100)'}}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Sub Total</span> <span style={{marginTop: 0}}>${format(subTotal)}
            </span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.4em' }}>
            <span>Shipping</span> <span style={{marginTop: 0}}>${format(shipping)}</span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.4em' }}>
            <span>Tax</span> <span style={{marginTop: 0}}>${format(tax)}</span>
        </div>
        <div className="hr"></div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.6em' }}>
            <span>Grand Total</span> <span style={{marginTop: 0}}>${format(total)}</span>
        </div>
    </div>
    )
}

const mapStateToProps = state => {
    
    return {
        shippingCost: state.cart.shipping,
        taxCost: state.cart.tax,
        items : state.cart.items
    }
}

export default connect(mapStateToProps)(CartSummary)