import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import { closeCart } from '../../redux/cart/CartActions'
import { connect } from 'react-redux'
import { closeSidebar } from '../../redux/sidebar/SidebarActions'

const ScrollToTop = ({isCartOpen, closeCart, isSideBarOpen, closeSidebar}) => {
    const { pathname } = useLocation()

    useEffect(() => {
      window.scrollTo(0, 0)

      if (isCartOpen){
          closeCart()
      }

      if (isSideBarOpen){
          closeSidebar()
      }
      
      // eslint-disable-next-line
    }, [pathname])

    return null
}

const mapStateToProps = state => {
    return {
        isCartOpen : state.cart.isOpen,
        isSideBarOpen : state.sidebar.isOpen
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeCart : () => dispatch(closeCart()),
        closeSidebar : () => dispatch(closeSidebar())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrollToTop)