import CartActionTypes from "./CartActionTypes"

let cart = localStorage.getItem('cart')
const initialState = cart ? JSON.parse(cart) : {
    isOpen: false,
    items: []
}

initialState.shipping = 60
initialState.tax = 25

const CartReducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case CartActionTypes.TOGGLE:
            return {
                ...state,
                isOpen: !state.isOpen
            }

        case CartActionTypes.OPEN:
            return {
                ...state,
                isOpen: true
            }

        case CartActionTypes.CLOSE:
            return {
                ...state,
                isOpen: false
            }

        case CartActionTypes.CLEAR:
            newState = {
                ...state,
                items : []
            }

            localStorage.setItem('cart', JSON.stringify(newState))

            return newState
        
        case CartActionTypes.ADD_ITEM:
            let exists = state.items.filter(item => item.id === action.payload.id)

            if (exists.length > 0) {
                let item = exists[0];
                item.qty = item.qty + action.payload.qty

                newState = {
                    ...state,
                    items: [...state.items.filter(i => i.id !== item.id), item]
                }

                localStorage.setItem('cart', JSON.stringify(newState))
                return newState
            }

            newState = {
                ...state,
                items : [...state.items, action.payload]
            }

            localStorage.setItem('cart', JSON.stringify(newState))
            return newState

        case CartActionTypes.DELETE_ITEM:
            newState = {
                ...state,
                items: state.items.filter(i => i.id !== action.payload)
            }

            localStorage.setItem('cart', JSON.stringify(newState))
            return newState
        
        case CartActionTypes.UPDATE_ITEM:
            let inCart = state.items.filter(item => item.id === action.payload.itemId)

            if (inCart.length > 0) {

                let updated = []
                state.items.forEach(item => {

                    if (item.id === action.payload.itemId) {
                        item.qty = action.payload.qty > 0 ? action.payload.qty : 1;
                    }

                    updated.push(item)
                })

                newState = {
                    ...state,
                    items: updated
                }

                localStorage.setItem('cart', JSON.stringify(newState))
                return newState
            }

            return state

        default:
            return state
    }
}

export default CartReducer