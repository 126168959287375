import AuthenticationActionTypes from "./AuthenticationActionTypes"
import { v4 as uuid } from 'uuid'
import NotificationTypes from '../notifications/NotificationTypes'
import { notify } from "../notifications/NotificationActions"

export const signingUp = () => ({
    type: AuthenticationActionTypes.SIGNING_UP
})

export const signedUp = () => ({
    type: AuthenticationActionTypes.SIGNED_UP
})

export const signupError = err => ({
    type: AuthenticationActionTypes.SIGNUP_ERROR,
    payload : {
        err
    }
})

export const emailVerificationSent = () => ({
    type: AuthenticationActionTypes.EMAIL_VERIFICATION_SENT
})

export const signingIn = () => ({
    type: AuthenticationActionTypes.LOGGING_IN
})

export const signedIn = () => ({
    type: AuthenticationActionTypes.LOGIN_SUCCESS
})

export const signinError = (err) => ({
    type: AuthenticationActionTypes.LOGIN_ERROR,
    payload: {
        err
    }
}) 

export const signingOut = () => ({
    type: AuthenticationActionTypes.LOGGING_OUT
})

export const signedOut = () => ({
    type: AuthenticationActionTypes.LOGGED_OUT
})

export const signOutError = err => ({
    type: AuthenticationActionTypes.LOGOUT_ERROR,
    payload : {
        err
    }
})

export const setAuthUser = user => ({
    type: AuthenticationActionTypes.SET_USER,
    payload: {
        user
    }
})

export const signUp = userDetails => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        dispatch(signingUp())
        getFirebase().auth().createUserWithEmailAndPassword(userDetails.email, userDetails.password)
            .then((res => {
                let user = getFirebase().auth().currentUser

                getFirestore().collection('users').doc(user.uid).set({
                    'displayName' : userDetails.displayName
                })

                user.updateProfile({
                    displayName: userDetails.displayName
                }).then(() => {
                    dispatch(setAuthUser(user))
                })

                dispatch(signedUp())
            }))
            .catch(err => {
                dispatch(signupError(err))
            }
        );
    }
}

export const signIn = creds => {
    return (dispatch, getState, {getFirebase}) => {
        dispatch(signingIn())
        getFirebase().auth().signInWithEmailAndPassword(creds.email, creds.password)
            .then(res => {
                dispatch(signedIn())
            })
            .catch(err => {
                dispatch(signinError(err))
            });
    }
}

export const signOut = () => {
    return (dispatch, getState, {getFirebase}) => {
        dispatch(signingOut())
        getFirebase().auth().signOut()
            .then(res => {
                dispatch(signedOut())
            })
            .catch(err => {
                dispatch(signOutError(err))
            })
    }
}

export const requestingPasswordReset = () => ({
    type: AuthenticationActionTypes.REQUESTING_RESET_EMAIL
})

export const requestResetEmailSuccess = () => ({
    type: AuthenticationActionTypes.REQUEST_RESET_EMAIL_SUCCESS
})

export const requestResetEmailError = err => ({
    type: AuthenticationActionTypes.REQUEST_RESET_EMAIL_ERROR,
    payload: {
        err
    }
})

export const sendPasswordResetEmail = email => {
    return (dispatch, getState, {getFirebase}) => {
        dispatch(requestingPasswordReset())
        getFirebase().auth().sendPasswordResetEmail(email)
            .then(() => {
                dispatch(requestResetEmailSuccess())

                let notification = {
                    id : uuid(),
                    type : NotificationTypes.SUCCESS,
                    message : 'Email sent. Please check your inbox.'
                }
                
                dispatch(notify(notification))
            }).catch(err => {
                dispatch(requestResetEmailError(err))
            });
    }
}