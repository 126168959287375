import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import PhysicalsHome from './pages/PhysicalsHome'
import ProductDetails from './pages/ProductDetails'
import Search from './pages/Search'
import ScrollToTop from './partials/ScrollToTop'
import Checkout from './pages/Checkout'
import DigitalsHome from './pages/DigitalsHome'
import GroceriesHome from './pages/GroceriesHome'
import Home from './pages/Home'
import Register from './pages/Register'
import Login from './pages/Login'
import ForgetPassword from './pages/ForgetPassword'
import ResetPassword from './pages/ResetPassword'
import OrderPayment from './pages/OrderPayment'
import Products from './pages/Products'
import AdminOrderDetails from './pages/Admin/AdminOrderDetails'

const Router = () => {
  return (
    <BrowserRouter>
        <ScrollToTop />
        <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/shops' component={Products} />
            <Route exact path='/search' component={Search} />
            <Route exact path='/checkout' component={Checkout} />
            <Route exact path='/digitals' component={DigitalsHome} />
            <Route exact path='/groceries' component={GroceriesHome} />
            <Route exact path='/physicals' component={PhysicalsHome} />
            <Route exact path='/register' component={Register} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/forgot-password' component={ForgetPassword} />
            <Route exact path='/reset-password' component={ResetPassword} />
            <Route path='/products/:id' component={ProductDetails} />
            <Route path='/orders/:id/payment' component={OrderPayment}/>
            <Route path='/admin/orders/:id' component={AdminOrderDetails}/>
        </Switch>
    </BrowserRouter>
  )
}

export default Router
