import React from 'react'
import { isLoaded, isEmpty, firestoreConnect } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'

const SmallProduct = ({product, item}) => {
    if (isLoaded(product) && !isEmpty(product)) {
        return (
            <Link className='shadowed small-product' title={`${item.qty} * ${product.name}`} to={`/products/${item.id}`}>
                <img style={{width: '100%', maxWidth: '100%', display: 'block'}} src={product.images ? product.images[0] : 'https://via.placeholder.com/1280x900.png'} alt={product.name}/>
            </Link>
        )
    }

    return null
}

export default compose(
    firestoreConnect(ownProps => [
        {
            collection: 'products',
            doc : ownProps.item.id,
            storeAs: `products/${ownProps.item.id}`
        }
    ]),

    connect((state, ownProps) => ({
        product: state.firestore.data[`products/${ownProps.item.id}`]
    }))
)(SmallProduct)