import OrdersActionTypes from "./OrdersActionTypes"
import { notify } from "../notifications/NotificationActions"
import NotificationTypes from '../notifications/NotificationTypes'
import { clearCart } from "../cart/CartActions"

export const placeOrderStart = (order) => ({
    type: OrdersActionTypes.PLACE_ORDER_START,
    payload: {
        order
    }
})

export const placeOrderSuccess = (orderRef) => ({
    type: OrdersActionTypes.PLACE_ORDER_SUCCESS,
    payload: {
        orderRef
    }
})

export const placeOrderError = error => ({
    type: OrdersActionTypes.PLACE_ORDER_ERROR,
    payload: {
        error
    }
})

export const addOrderItems = orderItems => ({
    type: OrdersActionTypes.ADD_ORDER_ITEMS,
    payload: {
        items: orderItems
    }
})

export const clearOrder = order => ({
    type: OrdersActionTypes.CLEAR_ORDER,
    payload: {
        order
    }
})

export const placeOrder = order => {
    return async (dispatch, getState, {getFirestore}) => {
        order = {...order, ...getState().cart, status: 'placed'}
        dispatch(placeOrderStart(order))
        
        try {
            let orderRef = await getFirestore().collection('orders').add(order)
            let notification = {
                type : NotificationTypes.SUCCESS,
                message : 'Order placed.'
            }

            dispatch(notify(notification))
            dispatch(placeOrderSuccess(orderRef))            
        }

        catch (e) {
            dispatch(placeOrderError(e))
        }
    }
}

export const getOrderItems = order => {
    return (dispatch, getState, {getFirestore}) => {
        let promises = []
        order.items.forEach((item) => {
            promises = [...promises, getFirestore().collection('products').doc(item.id).get()]
        })

        Promise.all(promises).then(products => {
            let merged = products.map(product => {
                return {
                    ...order.items.filter(i => i.id === product.id).reduce(i => i),
                    ...product.data()
                }
            })

            dispatch(addOrderItems(merged))
        })
    }
}

export const completePayment = order => {
    return (dispatch, getState, {getFirestore}) => {
        getFirestore().collection('orders').doc(order.id).set({status: 'pending'}, {merge: true}).then(() => {
            dispatch(clearOrder(order))
            dispatch(clearCart())
            let notification = {
                type : NotificationTypes.SUCCESS,
                message : 'Your order is now pending verification'
            }

            dispatch(notify(notification))
        })
        
    }
}

export const cancelOrder = order => {
    return (dispatch, getState, {getFirestore}) => {
        getFirestore().collection('orders').doc(order.id).set({status: 'cancelled'}, {merge: true}).then(() => {
            dispatch(clearOrder(order))
            dispatch(clearCart())
            let notification = {
                type : NotificationTypes.SUCCESS,
                message : 'Your order has been cancelled'
            }

            dispatch(notify(notification))
        })
    } 
}