import React from 'react'
import AppLayout from '../layouts/AppLayout'
import { format } from '../../helpers'
import {isLoaded, useFirestoreConnect, isEmpty } from 'react-redux-firebase'
import { connect, useSelector } from 'react-redux'
import { completePayment, getOrderItems, cancelOrder } from '../../redux/orders/OrdersActions'
import { useRouteMatch, Redirect } from 'react-router-dom'

const OrderPayment = ({completePayment, getOrderItems, items, shippingCost, taxCost, ecocashRate, placedOrderRef, orderPlaced, orderPlacing, cancelOrder}) => {

    document.title = 'TechBoss | Order Payment'

    const {params} = useRouteMatch()

    useFirestoreConnect([
        {
            collection : 'orders',
            doc: params.id,
            storeAs: `orders/${params.id}`
        }
    ])

    const order = useSelector(state => state.firestore.data[`orders/${params.id}`])

    if (!orderPlaced && !orderPlacing && placedOrderRef === null) {
        return <Redirect to='/' />
    }

    if (isLoaded(order) && !isEmpty(order) && items.length <= 0) {
        getOrderItems(order)
    }


    if (isLoaded(order) && !isEmpty(order) && items.length > 0) {
        let subTotal = items.reduce((sum, product) => (product ? (product.price * product.qty) : 0) + sum, 0)
        let shipping = items.length > 0 ? shippingCost : 0
        let tax = items.length > 0 ? taxCost : 0
        let total = subTotal + shipping + tax

        return (
            <AppLayout>
                <section>
                    <div className="section-title">
                        <h3 className="section-title__heading">Order Payment</h3>
                        <div className="section-title__desc">One last step before you get your items</div>
                        <div className="hr"></div>
                    </div>
                    <div className="checkout">
                        <form className="panel shadowed" style={{padding: '1em'}} method="post" onSubmit={e => {e.preventDefault(); completePayment({...order, id: params.id})}}>
                            <div className="section-title">
                                <h3 className="section-title__heading">Payment</h3>
                                <div className="section-title__desc">How do you want to pay</div>
                                <div className="hr"></div>
                            </div>
                            <ul className="product-options__list">
                                <li className="product-option selected">EcoCash</li>
                                <li className="product-option ">Visa / Mastercard</li>
                                <li className="product-option">TeleCash</li>
                                <li className="product-option">OneMoney</li>
                                <li className="product-option">Paypal</li>
                                <li className="product-option">Skrill</li>
                            </ul>
                            <div className="hr"></div>
                            <div style={{marginTop: '1em', marginBottom: '0.8em'}}>
                                {isLoaded(order) && 
                                <ol style={{marginTop: '2em', marginLeft: '1em', lineHeight: '1.7em'}}>
                                    <li>1. Pay <strong style={{fontWeight: 400}}>RTGS ${format((total * ecocashRate))}</strong> to number <strong style={{fontWeight: 400}}>0782632563</strong> it reads <strong style={{fontWeight: 400}}>Berzel Tumbude</strong>. Short code <strong style={{fontWeight: 400, textDecoration: 'underline'}}>*151*1*1*0782632563#</strong></li>
                                    <li>2. Send proof of payment & order id - <strong style={{fontWeight: 400}}>{params.id}</strong> to 0782632563. <a href="https://wa.me/263782632563">WhatsApp</a> or SMS will do</li>
                                    <li>3. Don't forget to complete your order by hitting the big button below. <strong style={{fontWeight: 400}}>We won't be able to process your order until then</strong></li>
                                    <li>4. Receive your order in 6 hours and enjoy</li>
                                </ol>}
                            </div>
                            <div style={{minHeight: '2em'}}></div>
                            <button type='submit' className="btn btn--secondary" style={{width: '100%'}}>Complete Payment</button>
                            <div className="hr" style={{margin: '1.5em 0'}}></div>
                            <button onClick={e => {e.preventDefault(); cancelOrder({...order, id: params.id})}} className="btn btn--secondary" style={{width: '100%', backgroundColor: '#DD4B39'}}>Cancel Order</button>
                        </form>
                    </div>
                </section>
            </AppLayout>
        )
    }

    return (
        <AppLayout>
            <div>Loading...</div>
        </AppLayout>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        completePayment : order => dispatch(completePayment(order)),
        cancelOrder: order => dispatch(cancelOrder(order)),
        getOrderItems : order => dispatch(getOrderItems(order))
    }
}

const mapStateToProps = state => {
    return {
        ecocashRate: state.application.ecocashRate,
        shippingCost: state.cart.shipping,
        taxCost: state.cart.tax,
        items: state.orders.cartItems,

        orderPlacing : state.orders.placing,
        orderPlaced : state.orders.placed,
        placedOrderRef : state.orders.orderRef
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderPayment)