import React from 'react'

const Notification = ({notification}) => {
    return (
        <li>
            <span className={`notification notification--${notification.type}`}> 
                {notification.message}
            </span>
        </li>
    )
}

export default Notification