import React, { Component } from 'react'
import { connect } from 'react-redux'

import Notifications from '../partials/Notifications'
import ShoppingCart from '../partials/ShoppingCart'
import Sidebar from '../partials/Sidebar'
import TopBar from '../partials/TopBar'
import { closeSidebar } from '../../redux/sidebar/SidebarActions'
import { closeCart } from '../../redux/cart/CartActions'

class AppLayout extends Component {

    componentDidUpdate(oldProps) {
        if (oldProps.isSideBarOpen) {
            this.props.closeSidebar()
        }
    }

    render({isSideBarOpen, isShoppingCartOpen, closeShoppingCart, closeSidebar, children} = this.props){
        return (
            <React.Fragment>
                <Sidebar />
        
                <div className="container" onClick={() => {
                    if (isSideBarOpen){
                        closeSidebar()
                    }
    
                    if (isShoppingCartOpen) {
                        closeShoppingCart()
                    }
                }}>
    
                    <TopBar />
                
                    <div className="content">
                        { children }
                    </div>
                </div>
    
                <ShoppingCart/>
                <Notifications />
                
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeSidebar: () => dispatch(closeSidebar()),
        closeShoppingCart: () => dispatch(closeCart())
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        isSideBarOpen : state.sidebar.isOpen,
        isShoppingCartOpen : state.cart.isOpen
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout)
